// 将excel类文件通过blob流保存到本地
function saveAs(blob, filename) {
  const eleA = document.createElement('a');
  eleA.download = filename;
  eleA.download = eleA.download.slice(1, eleA.download.length - 1);
  eleA.style.display = 'none';
  eleA.href = URL.createObjectURL(blob);
  document.body.appendChild(eleA);
  eleA.click();
  URL.revokeObjectURL(eleA.href); //释放URL对象
  document.body.removeChild(eleA);
}
export default function responseCheck(res) {
  const { headers, data } = res;
  console.log(headers['content-type']);

  const filename = decodeURI(headers['content-disposition']?.split('filename=')[1] ?? '');
  let blob = new Blob([data], { type: headers['content-type'] });
  saveAs(blob, filename);
}
