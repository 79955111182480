import { getToken, removeToken } from '@/utils/auth';
// import { resetRouter } from '@/router';
import router from '@/router';
const state = {
  token: '', // 用户 token
  id: null, // 用户 id
  name: '', // 姓名
  workId: '',
  avatar: '', // 头像
  elTreeMenu: [
    {
      id: 1,
      name: 'route.WorkRecord',
      label: '',
      parentName: 'SubmitWorkingHours',
      pdfUrl:
        'https://janus-pmf.oss-cn-shanghai.aliyuncs.com/PMF%E6%93%8D%E4%BD%9C%E6%89%8B%E5%86%8C%EF%BC%88%E9%A6%96%E9%A1%B5%E5%B7%A5%E6%97%B6%E5%A1%AB%E6%8A%A5%EF%BC%89.pdf',
      sub: {
        subName: 'TaskItemProcess',
        subPdfUrl:
          'https://janus-pmf.oss-cn-shanghai.aliyuncs.com/PMF%E3%80%90%E4%BB%BB%E5%8A%A1%E9%A1%B9%E8%BF%9B%E5%BA%A6%E3%80%91%E6%93%8D%E4%BD%9C%E6%8C%87%E5%BC%95.pdf',
        enSubPdfUrl: 'https://janus-pmf.oss-cn-shanghai.aliyuncs.com/PMF%E3%80%90Task%20Item%20process%E3%80%91Operation%20Guide.pdf',
      },
    },
    {
      id: 2,
      name: 'route.PersonalWorking',
      label: '',
      enPdfUrl: 'https://janus-pmf.oss-cn-shanghai.aliyuncs.com/PMF%20%5BSubmitWorking%20Hours%5D%20Operation%20Guide.pdf',
      pdfUrl:
        'https://janus-pmf.oss-cn-shanghai.aliyuncs.com/%E3%80%90%E6%8F%90%E4%BA%A4%E5%B7%A5%E6%97%B6%E3%80%91%E6%93%8D%E4%BD%9C%E6%8C%87%E5%BC%95.pdf',
    },
    {
      id: 6,
      name: 'route.AuditManagement',
      label: '',
      pdfUrl:
        'https://janus-pmf.oss-cn-shanghai.aliyuncs.com/PMF%E3%80%90%E5%B7%A5%E6%97%B6%E5%AE%A1%E6%A0%B8%E3%80%91%E6%93%8D%E4%BD%9C%E6%8C%87%E5%BC%95.pdf',
    },
    {
      id: 7,
      name: 'route.ContractManagement',
      label: '',
      enPdfUrl: 'https://janus-pmf.oss-cn-shanghai.aliyuncs.com/PMF%E3%80%90Contract%20Management%E3%80%91Operation%20guide.pdf',
      pdfUrl:
        'https://janus-pmf.oss-cn-shanghai.aliyuncs.com/PMF%E3%80%90%E5%90%88%E5%90%8C%E7%AE%A1%E7%90%86%E3%80%91%E6%93%8D%E4%BD%9C%E6%8C%87%E5%BC%95.pdf',
    },
    {
      id: 8,
      name: 'route.ProjectManagement',
      label: '',
      pdfUrl:
        'https://janus-pmf.oss-cn-shanghai.aliyuncs.com/PMF%E3%80%90%E9%A1%B9%E7%9B%AE%E7%AE%A1%E7%90%86%E3%80%91%E5%8A%9F%E8%83%BD%E6%93%8D%E4%BD%9C%E6%8C%87%E5%BC%95.pdf',
    },
    /*     {
      name: 'route.ProjectData',
      label: '',
      id: 3,
      children: [
        {
          id: 31,
          name: 'route.ProjectStatement',
          label: '',
          pdfUrl:
            'https://janus-pmf.oss-cn-shanghai.aliyuncs.com/PMF%E6%93%8D%E4%BD%9C%E6%89%8B%E5%86%8C%EF%BC%88%E9%A1%B9%E7%9B%AE%E6%8A%A5%E8%A1%A8%EF%BC%89.pdf',
        },
        {
          id: 32,
          name: 'route.ProjectList',
          label: '',
          pdfUrl: '',
        },
      ],
    }, */
    {
      name: 'route.ResourceManagement',
      id: 4,
      children: [
        {
          id: 41,
          name: 'route.SponsorManagement',
          label: '',
          pdfUrl:
            'https://janus-pmf.oss-cn-shanghai.aliyuncs.com/PMF%E6%93%8D%E4%BD%9C%E6%89%8B%E5%86%8C%EF%BC%88%E8%B5%84%E6%BA%90%E7%AE%A1%E7%90%86%EF%BC%89.pdf',
        },
        {
          id: 42,
          name: 'route.ClientManagement',
          label: '',
          enPdfUrl: 'https://janus-pmf.oss-cn-shanghai.aliyuncs.com/PMF%E3%80%90Client%20maintenance%E3%80%91Operation%20guide.pdf',
          pdfUrl:
            'https://janus-pmf.oss-cn-shanghai.aliyuncs.com/PMF%E3%80%90%E5%AE%A2%E6%88%B7%E7%BB%B4%E6%8A%A4%E3%80%91%E6%93%8D%E4%BD%9C%E6%8C%87%E5%BC%95.pdf',
        },
        {
          id: 43,
          name: 'route.PlanManagement',
          label: '',
          pdfUrl:
            'https://janus-pmf.oss-cn-shanghai.aliyuncs.com/PMF%E6%93%8D%E4%BD%9C%E6%89%8B%E5%86%8C%EF%BC%88%E8%B5%84%E6%BA%90%E7%AE%A1%E7%90%86%EF%BC%89.pdf',
        },
      ],
    },
    {
      id: 5,
      name: 'route.SystemConfig',
      label: '',
      children: [
        {
          id: 51,
          name: 'route.DepartmentManagement',
          label: '',
          pdfUrl:
            'https://janus-pmf.oss-cn-shanghai.aliyuncs.com/PMF%E6%93%8D%E4%BD%9C%E6%89%8B%E5%86%8C%EF%BC%88%E7%B3%BB%E7%BB%9F%E8%AE%BE%E7%BD%AE%EF%BC%89.pdf',
        },
        {
          id: 52,
          name: 'route.RoleManagement',
          label: '',
          pdfUrl:
            'https://janus-pmf.oss-cn-shanghai.aliyuncs.com/PMF%E6%93%8D%E4%BD%9C%E6%89%8B%E5%86%8C%EF%BC%88%E7%B3%BB%E7%BB%9F%E8%AE%BE%E7%BD%AE%EF%BC%89.pdf',
        },
        {
          id: 53,
          name: 'route.UserManagement',
          label: '',
          pdfUrl:
            'https://janus-pmf.oss-cn-shanghai.aliyuncs.com/PMF%E6%93%8D%E4%BD%9C%E6%89%8B%E5%86%8C%EF%BC%88%E7%B3%BB%E7%BB%9F%E8%AE%BE%E7%BD%AE%EF%BC%89.pdf',
        },
      ],
    },
  ],
  roleNames: [], // 角色
  directorName: '', // director id
  boName: '', // bo id
  pmName: '', // pm id
};
const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_ID: (state, id) => {
    state.id = id;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar;
  },
  SET_WORKID: (state, workId) => {
    state.workId = workId;
  },
  SET_ROLES: (state, roleNames) => {
    state.roleNames = roleNames;
  },
  SET_DIRECTOR: (state, directorName) => {
    state.directorName = directorName;
  },
  SET_BO: (state, boName) => {
    state.boName = boName;
  },
  SET_PM: (state, pmName) => {
    state.pmName = pmName;
  },
};

const actions = {
  // user login
  login({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', getToken());
      resolve();
    });
  },

  // get user info
  getInfo({ commit }, state) {
    let id = state.id;
    let name = state.name;
    let avatar = state.avatar;
    let workId = state.workId;
    let roleNames = state.roleNames;
    let directorName = state.directorName;
    let boName = state.boName;
    let pmName = state.pmName;

    const data = {
      id,
      name,
      avatar,
      workId,
      roleNames,
      directorName,
      boName,
      pmName,
    };
    return new Promise(resolve => {
      commit('SET_ID', id);
      commit('SET_NAME', name);
      commit('SET_AVATAR', avatar);
      commit('SET_WORKID', workId);
      commit('SET_ROLES', roleNames);
      commit('SET_DIRECTOR', directorName);
      commit('SET_BO', boName);
      commit('SET_PM', pmName);
      resolve(data);
    });
  },

  // user logout
  logout({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '');
      commit('SET_ROLES', []);
      removeToken();
      // resetRouter();
      resolve();
      router.push('/login');
    });
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '');
      commit('SET_ROLES', []);
      removeToken();
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
