/*
 *@Author: 胡广源
 *@Date: 2022-04-08 13:19:50
 *@Description: table滚动逻辑设计
 *@params: scrollHeight:元素总高度，即滚动条中的内容，只读属性，不带px单位。
 *@params: scrollTop:内容向上滚动的距离，无滚动条时值为0
 *@params: clientHeight: 元素内容区的高度，即为可视区域的高度
 *@params:scrollHeight-scrollTop-clientHeight=0 此时scrollDistance为0，即为滚动条滚到底部的时候了
 */

import Vue from 'vue';
const install = function (Vue) {
  Vue.directive('loadmore', {
    bind(el, binding) {
      const selectWrap = el.querySelector('.el-table__body-wrapper');
      selectWrap.addEventListener('scroll', function () {
        const scrollDistance = this.scrollHeight - this.scrollTop.toFixed(0) <= this.clientHeight;

        if (scrollDistance) {
          binding.value();
        }
      });
    },
  });
  Vue.directive('select-loadmore', {
    bind(el, binding) {
      const selectOption = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap');
      selectOption.addEventListener('scroll', function () {
        const condition = this.scrollHeight - this.scrollTop.toFixed(0) <= this.clientHeight;
        if (condition) {
          binding.value();
        }
      });
    },
  });
};

if (window.Vue) {
  Vue.use(install);
}
export default install;
