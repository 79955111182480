<template>
  <div class="navbar">
    <hamburger :is-active="sidebar.opened" class="hamburger-container" @toggleClick="toggleSideBar" />

    <breadcrumb class="breadcrumb-container" />

    <div class="right-menu">
      <el-popover placement="top-start" popper-class="poppers" width="200" trigger="hover">
        <p class="title">{{ $t('commonVariables.HelpDocumentation') }}</p>
        <p class="content">{{ $t('commonVariables.viewOperationManual') }}</p>
        <div slot="reference" @click="showDialog" class="helpCheck">
          <GLIcon icon="icon-bangzhu" class="avatarHelp" />
        </div>
      </el-popover>
      <screenfull id="screenfull" class="right-menu-item hover-effect fullScreen" />
      <lang-select class="right-menu-item hover-effect international" />

      <el-dropdown @command="handleCommand">
        <span class="right-menu-item hover-effect el-dropdown-link personName">
          <img :src="avatar" class="personalAvatar" alt="用户头像" />
          <p class="des">{{ userName }}</p>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="personal">个人信息</el-dropdown-item>
          <el-dropdown-item command="setting">设置</el-dropdown-item>
          <el-dropdown-item command="logOut">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <SimpleDialog
      class="PlanDialog"
      :before-close="handleClose"
      @onCancel="handleClose"
      :modalAppendFront="false"
      :modalCloseBackground="true"
      :title="$t('commonVariables.HelpDocumentation')"
      :visible.sync="helpDialogVisible"
      width="800px"
    >
      <div class="boxContainer">
        <div class="siderContainer">
          <el-tree
            node-key="id"
            :current-node-key="currentNodeKey"
            ref="menuTree"
            :data="mapTreeMenu"
            :props="defaultProps"
            highlight-current
            @node-click="handleNodeClick"
            :render-content="renderContent"
          ></el-tree>
        </div>
        <div class="PDFContainer">
          <div class="main">
            <div class="mainPdf">
              <img src="~@/assets/tabIcon/pdf_img.png" alt="PDF示意图" />
              <div class="titleDesc">
                {{ currentInfo.label }}-{{
                  currentInfo.sub ? $t('commonVariables.' + currentInfo.parentName) : $t('commonVariables.HelpDocumentation')
                }}.pdf
              </div>
              <a
                :href="$i18n.locale == 'en' && currentInfo.enPdfUrl ? currentInfo.enPdfUrl : currentInfo.pdfUrl"
                target="_blank"
                rel="noopener noreferrer"
              >
                <el-button :class="['check', { btnWidth: $i18n.locale != 'en' }]" type="default">
                  <span class="descCheck">{{ $t('commonVariables.ViewPDF') }}</span>
                </el-button>
              </a>
            </div>
            <div v-if="currentInfo.sub" class="subPdf">
              <img src="~@/assets/tabIcon/pdf_img.png" alt="PDF示意图" />
              <div class="titleDesc">{{ currentInfo.label }}-{{ $t('commonVariables.' + currentInfo.sub.subName) }}.pdf</div>
              <a
                :href="$i18n.locale == 'en' && currentInfo.sub.enSubPdfUrl ? currentInfo.sub.enSubPdfUrl : currentInfo.sub.subPdfUrl"
                target="_blank"
                rel="noopener noreferrer"
              >
                <el-button :class="['check', { btnWidth: $i18n.locale != 'en' }]" type="default">
                  <span class="descCheck">{{ $t('commonVariables.ViewPDF') }}</span>
                </el-button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </SimpleDialog>
    <tab-pane
      v-if="dialogVisible"
      :style="recordStyle"
      :tabList="tabList"
      :isActive="isActiveTab"
      :activeIndex="activeIndex"
      highlight-current-row
      @isActive="switchTab"
      :class="['tabs', { personalInfo: activeIndex ? false : true }]"
    >
      <div class="tableContainer">
        <el-descriptions class="margin-top descriptions" :column="2" border v-show="descriptionsType === 1">
          <el-descriptions-item
            content-class-name="userInfo"
            :contentStyle="{ minWidth: '500px' }"
            :labelStyle="{ textAlign: 'left', color: '#515a6e' }"
          >
            <template slot="label"> 用户名： </template>
            {{ userName }}
          </el-descriptions-item>
          <el-descriptions-item
            content-class-name="userInfo"
            :contentStyle="{ minWidth: '500px' }"
            :labelStyle="{ textAlign: 'left', color: '#515a6e' }"
          >
            <template slot="label"> Manager： </template>
            {{ pmName }}
          </el-descriptions-item>
          <el-descriptions-item
            content-class-name="userInfo"
            :contentStyle="{ minWidth: '500px' }"
            :labelStyle="{ textAlign: 'left', color: '#515a6e' }"
          >
            <template slot="label"> 工号： </template>
            {{ workId }}
          </el-descriptions-item>
          <el-descriptions-item
            content-class-name="userInfo"
            :contentStyle="{ minWidth: '500px' }"
            :labelStyle="{ textAlign: 'left', color: '#515a6e' }"
          >
            <template slot="label">Senior Manager： </template>
            {{ boName }}
          </el-descriptions-item>
          <el-descriptions-item
            content-class-name="userInfo"
            :contentStyle="{ minWidth: '500px' }"
            :labelStyle="{ textAlign: 'left', color: '#515a6e' }"
          >
            <template slot="label">身份/角色： </template>
            {{ roleNames.toString() }}
          </el-descriptions-item>
          <el-descriptions-item
            content-class-name="userInfo"
            :contentStyle="{ minWidth: '500px' }"
            :labelStyle="{ textAlign: 'left', color: '#515a6e' }"
          >
            <template slot="label"> 直属总监： </template>
            {{ directorName }}
          </el-descriptions-item>
        </el-descriptions>

        <el-descriptions class="margin-top descriptions" :column="1" border v-show="descriptionsType === 2">
          <el-descriptions-item content-class-name="userInfo" :labelStyle="{ textAlign: 'left', color: '#515a6e' }">
            <template slot="label"> 菜单名称： </template>
            可见性
          </el-descriptions-item>
          <el-descriptions-item content-class-name="userInfo" :labelStyle="{ textAlign: 'left', color: '#515a6e' }">
            <template slot="label">首页： </template>
            可见
          </el-descriptions-item>
          <el-descriptions-item v-for="(item, index) in menuData" :key="item.menuId" :labelStyle="{ textAlign: 'left', color: '#515a6e' }">
            <template slot="label"> {{ item.menuName }} </template>
            <div v-if="item.canEdit">
              <el-radio-group v-model="menuData[index].visible" class="descRadiogroup">
                <el-radio :label="true">可见</el-radio>
                <el-radio :label="false">隐藏</el-radio>
              </el-radio-group>
            </div>
            <div v-else>
              <span>{{ item.visible ? '可见' : '隐藏' }}</span>
            </div>
          </el-descriptions-item>
        </el-descriptions>
      </div>
      <el-button v-show="descriptionsType === 2" @click="saveDescriptionLog" class="savebtn" size="medium" type="primary">{{
        $t('workRecord.save')
      }}</el-button>
    </tab-pane>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Hamburger from './Hamburger';
import Screenfull from '@/components/Screenfull';
import LangSelect from '@/components/LangSelect';
import Breadcrumb from './Breadcrumb';

import { debounce } from '@/utils/debounce';
import systemConfigApi from '@/api/modules/systemConfigApi.api.js';
export default {
  name: 'Navbar',
  components: {
    Hamburger,
    Breadcrumb,
    Screenfull,
    LangSelect,
  },
  data() {
    return {
      mapTreeMenu: [],
      currentNodeKey: 1,
      currentInfo: {
        id: 1,
        label: '首页',
        pdfUrl: '',
      },
      defaultProps: {
        children: 'children',
        label: 'label',
      },
      helpDialogVisible: false,
      menuData: [],
      activeIndex: 0,
      radio: 1,
      descriptionsType: 1,
      mainContainerHeight: 0,
      isActiveTab: 'personal',
      tabList: [
        {
          label: '个人信息',
          name: 'personal',
        },
        {
          label: '设置',
          name: 'setting',
        },
      ],
    };
  },
  created() {
    this.disabledFn(this.mapTreeMenu);
    this.refreshTabs();
  },
  mounted() {
    this.$nextTick(() => {
      this.resizeObserverSize();
    });
    window.addEventListener('resize', this.resizeObserverSize);
  },
  watch: {
    //   监听路由变化，自动dispatch一个action
    $route() {
      this.refreshTabs();
    },
    helpDialogVisible(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.mapTreeMenu = JSON.parse(JSON.stringify(this.elTreeMenu));
          const callArr = arr => {
            arr.forEach(item => {
              if (item.children && item.children.length) {
                item.children.forEach(val => {
                  val.label = this.$t(val.name);
                });
                callArr(item.children);
              }

              item.label = this.$t(item.name);
            });
          };
          callArr(this.mapTreeMenu);
          this.$refs.menuTree.setCurrentKey(1);
          this.currentInfo = this.mapTreeMenu[0];
        });
      }
    },
  },
  computed: {
    dialogVisible() {
      return this.$store.state.app.dialogVisible;
    },
    /*     sidebar() {
      return this.$store.state.app.sidebar;
    },
    userName() {
      return this.$store.state.user.name;
    },
    workId() {
      return this.$store.state.user.workId;
    }, */
    ...mapGetters(['workId', 'elTreeMenu', 'sidebar', 'userName', 'avatar', 'device', 'roleNames', 'directorName', 'pmName', 'boName']),
    ...mapGetters(['getCacheView']),
    recordStyle() {
      return {
        zIndex: 99999,
        height: this.mainContainerHeight + 'px',
        background: '#fff',
        position: 'fixed',
        top: '50px',
        transform: 'translate(-50%)',
        left: '' + `${this.sidebar.opened ? '54.6%' : '50.06%'}` + '',
        minWidth: 'calc(100% - 40px - ' + `${this.sidebar.opened ? 208 : 54}` + 'px)',
        margin: ' 13px 20px 20px 20px',
        padding: ' 0 32px !important',
      };
    },
  },
  methods: {
    resizeObserverSize() {
      const resizeDom = debounce(() => {
        this.mainContainerHeight = window.innerHeight - 50 - 90 - 33;
      }, 100);
      resizeDom();
    },
    disabledFn(data) {
      if (!data.pdfUrl) {
        if (data.children?.length) {
          data.children.map(item => {
            item.pdfUrl ? null : (item.disabled = true);
          });
        } else {
          data.disabled = true;
        }
      }
    },
    renderContent(h, { node }) {
      if (node.childNodes.length) {
        return (
          <span>
            <span>{node.label}</span>
          </span>
        );
      }

      if (!node.data.pdfUrl && !node.data.enPdfUrl) {
        return (
          <span class="custom-tree-node disabled-node">
            <span>{node.label}</span>
          </span>
        );
      } else {
        return (
          <span>
            <span>{node.label}</span>
          </span>
        );
      }
    },
    handleNodeClick(data) {
      if (!data.children) {
        this.currentInfo = { ...data };
      }
    },
    handleClose() {
      this.helpDialogVisible = false;
    },
    showDialog() {
      this.$store.commit('app/SETPERSONALDATA', false);
      this.helpDialogVisible = true;
    },
    async saveDescriptionLog() {
      const res = await systemConfigApi.saveAuthSetting(this.menuData);
      if (res[2].status === 200) {
        this.$message.success('保存成功');
      }
    },
    switchTab(tab) {
      if (tab === 'setting') {
        this.isActiveTab = 'setting';
        this.activeIndex = 1;
        this.descriptionsType = 2;
      } else if (tab === 'personal') {
        this.isActiveTab = 'personal';
        this.activeIndex = 0;
        this.descriptionsType = 1;
      }
    },
    async handleCommand(type) {
      if (type === 'logOut') {
        await systemConfigApi.logout();

        await this.$store.dispatch('user/logout');
        return;
      }
      this.switchTab(type);
      const res = await systemConfigApi.getAuthSetting();
      if (res && res.status === 200) {
        this.menuData = res?.data?.data?.menuVisions || [];
        this.$store.commit('app/SETPERSONALDATA', true);
      }
    },

    refreshTabs() {
      // 路由组件名称(自定义)
      const componentName = this.$route.meta.title;
      //   路由组件path
      const detail = this.$route.path;
      //   当前路由需要显示到tab标签上名字，如“项目报表”
      const name = this.$route.meta.name;
      const alive = this.$route.meta.keepAlive;
      if (name) {
        this.$store.dispatch('app/commitToolBar', { name, detail, componentName, alive });
      }
    },
    toggleSideBar() {
      this.$store.commit('app/TOGGLE_SIDEBAR');
    },
    async logout() {
      await this.$store.dispatch('user/logout');
      this.$router.push(`/login?redirect=${this.$route.fullPath}`);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .disabled-node {
  cursor: not-allowed !important;
  color: #c5c8ce !important;
}
.poppers {
  padding: 0 !important;
}
</style>
<style lang="scss" scoped>
.poppers {
  .title {
    margin: 4px 0;
    margin-top: 0;
    padding: 5px 0;
    padding-left: 8px;
    font-size: 14px;
    margin-left: 2px;
    font-weight: 700;

    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }
  .content {
    font-weight: 400;
    padding-left: 8px;
    margin: 8px 0;
  }
}

.navbar {
  width: 100%;
  display: flex;
  // height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  .tabs {
    height: 92vh !important;
    .tableContainer {
      padding: 23px 32px;
      width: 100%;
      // border-top: 1px solid #e8e8e8;
      .userInfo {
        color: #515a6e !important;
      }
      .descRadiogroup {
        .el-radio {
          color: #515a6e;
        }
        .el-radio__input.is-checked + .el-radio__label {
          color: #2d8cf0;
        }
      }
      .descriptions {
        height: 720px;
        border: 1px solid #e8e8e8;
        border-left: none;
        border-right: none;
        overflow-y: auto;

        font-size: 14px;
        color: #515a6e !important;
        ::v-deep .el-descriptions-item__label {
          text-align: center;
          width: 184px;
        }
        ::v-deep .el-descriptions-item__content {
          padding-left: 24px;
        }
        ::v-deep .el-descriptions__table {
          color: #515a6e !important;
          height: 100%;
          tbody:first-of-type {
            th,
            td {
              border-top: none;
            }
          }
          tbody:last-of-type {
            th,
            td {
              border-bottom: none;
            }
          }
        }
      }
    }
    .savebtn {
      display: block;
      margin: 0 auto;
      height: 36px;
      padding-top: 1px;
      padding-bottom: 1px;
      line-height: 34px;
    }
  }
  .PlanDialog {
    ::v-deep .el-dialog__header {
      border-bottom: none !important;
      padding-left: 32px !important;
    }
    .boxContainer {
      display: flex;
      border-radius: 4px;
      box-sizing: border-box;
      margin: 48px 32px 32px;
      margin-top: 0;
      height: 436px;
      border: 1px solid #e9e9e9;
      .siderContainer {
        ::v-deep .el-tree {
          color: #515a6e;
          padding: 12px;
        }
        color: #515a6e;
        width: 254px;
        overflow-y: auto;
        border-right: 1px solid #e9e9e9;
        height: 100%;
        ::v-deep .el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
          background-color: rgba(24, 144, 255, 0.05);
          font-weight: 400;
          color: #2d8cf0 !important;
        }
        ::v-deep .el-tree-node__label {
          margin-top: 8px;
          margin-bottom: 8px;
          font-size: 14px;
        }
      }
      .PDFContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        .main {
          position: relative;
          display: flex;
          justify-content: center;
          align-content: center;
          .mainPdf,
          .subPdf {
            padding: 0 20px;
            text-align: center;
            img {
              width: 72px;
              height: 79px;
            }
            .titleDesc {
              font-size: 14px;

              font-weight: 500;
              color: #515a6e;
              padding-top: 21px;
              padding-bottom: 30px;
            }
            .check,
            .checked {
              font-weight: 400;
              .descCheck {
                display: inline-block;
                margin-left: 4px;
              }
            }
            .btnWidth {
              width: 147px;
            }
            .check::before {
              content: '';
              display: inline-block;
              vertical-align: bottom;
              width: 16px;
              height: 16px;
              background: url('~@/assets/tabIcon/checkUrl_unactived.png') no-repeat;
              background-size: cover;
            }
            .check:hover::before {
              vertical-align: bottom;
              background: url('~@/assets/tabIcon/checkUrl_actived.png') no-repeat;
              background-size: cover;
            }
          }
        }
      }
    }
  }

  .hamburger-container {
    padding-right: 16px !important;
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: #f8f8f9;
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .right-menu {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    margin-left: auto;
    margin-right: 15px;
    .fullScreen,
    &:focus {
      outline: none;
    }
    #screenfull,
    .international,
    .helpCheck {
      cursor: pointer;
      text-align: center;
      width: 40px;
      height: 50px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    .international {
      padding-top: 5px;
    }
    .helpCheck:hover {
      background: #f8f8f9;
    }

    .avatarHelp {
      margin-top: 1px;
      width: 23px;
      height: 23px;
    }
    .avatarHelp :hover {
      background: #f8f8f9;
    }
    .personName {
      font-size: 14px !important;
      color: #17233d;
      min-width: 114px;
      display: inline-block;
      height: 50px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 16px;

      .personalAvatar {
        display: inline-block;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin: 0;
        margin-right: 8px;
      }
      .des {
        margin-top: 20px;
      }
    }
  }

  .right-menu-item {
    display: inline-block;
    padding: 0 12px;
    font-size: 21px;
    color: #515a6e;
    vertical-align: text-bottom;

    &.hover-effect {
      cursor: pointer;
      transition: background 0.3s;

      &:hover {
        background: #f8f8f9;
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
  .personalInfo {
    .tableContainer {
      .descriptions {
        height: 100%;
      }
    }
  }
}
</style>
