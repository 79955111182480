<template>
  <section class="app-main">
    <transition name="fade-transform" mode="out-in">
      <el-main class="main">
        <keep-alive :include="getCacheView">
          <router-view :key="key" />
        </keep-alive>
      </el-main>
    </transition>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'AppMain',
  computed: {
    key() {
      return this.$route.path;
    },

    ...mapGetters(['getCacheView']),
  },
};
</script>

<style lang="scss" scoped>
.app-main {
  width: 100%;
  // height: 100vh;
  box-sizing: border-box;
  overflow-x: hidden;
  background-color: #f8f8f8f9;
  .main {
    margin-top: 5px;
    padding: 0;
    // padding-bottom: 15px;
    position: relative;
    // height: 100%;
  }
}
/* #isMobile .app-main {
  overflow-x: scroll;
} */
</style>
