<template>
  <div :id="idObj" :class="['app-wrapper', classObj, { isMobileWidth: device === 'mobile' }]">
    <div v-if="device === 'mobile' && sidebar.opened" class="drawer-bg" @click="handleClickOutside" />
    <sidebar :class="['sidebar-container', { 'ensidebar-container': $i18n.locale == 'en' }]" />
    <div :class="['main-container', { 'en-main-container': $i18n.locale == 'en' }]">
      <div :class="{ 'fixed-header': fixedHeader }">
        <navbar />
      </div>
      <ToolBar />
      <app-main />
    </div>
  </div>
</template>

<script>
import { Navbar, Sidebar, AppMain, ToolBar } from './components';
import ResizeMixin from './mixin/ResizeHandler';

export default {
  name: 'Layout',
  components: {
    Navbar,
    Sidebar,
    ToolBar,
    AppMain,
  },
  mixins: [ResizeMixin],
  computed: {
    sidebar() {
      return this.$store.state.app.sidebar;
    },
    device() {
      return this.$store.state.app.device;
    },
    fixedHeader() {
      // return this.$store.state.settings.fixedHeader;
      return true;
    },
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
      };
    },
    idObj() {
      return this.device === 'mobile' ? 'isMobile' : '';
    },
  },
  methods: {
    handleClickOutside() {
      const withoutAnimation = false;
      this.$store.commit('app/CLOSE_SIDEBAR', withoutAnimation);
    },
  },
};
</script>

<style lang="scss" scoped>
.app-wrapper {
  @include clearfix;
  position: relative;
  height: 100%;
  width: 100%;
  // min-width: 1440px;
  &#isMobile.openSidebar {
    position: fixed;
    top: 0;
  }

  .drawer-bg {
    background: #17233d;
    opacity: 0.3;
    width: 100%;
    top: 0;
    height: 100%;
    position: absolute;
    z-index: 999;
  }
}
.isMobileWidth {
  min-width: 900px !important;
}
.fixed-header {
  position: relative;
  width: 100%;
  transition: width 0.28s;
  margin-bottom: 5px;
}

.hideSidebar .fixed-header {
  width: 100%;
}

#isMobile .fixed-header {
  width: 100%;
}
</style>
