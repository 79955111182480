<template>
  <div id="app">
    <Layout></Layout>
  </div>
</template>
<script>
import Layout from './layout/index';

export default {
  name: 'App',
  components: {
    Layout,
  },
};
</script>
<style lang="scss">
#app {
  font-family: MicrosoftYahei, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #515a6e;
}
</style>
