<template>
  <div class="sidebar-logo-container" :class="{ collapse: collapse }">
    <transition name="sidebarLogoFade">
      <router-link v-if="collapse" key="collapse" class="sidebar-logo-link" to="/">
        <img v-if="imgUrl01" :src="imgUrl01" class="sidebar-collapse-logo" />
        <h1 v-else class="sidebar-title">{{ title }}</h1>
      </router-link>
      <router-link v-else key="expand" class="sidebar-logo-link" to="/">
        <img v-if="imgUrl02" :src="imgUrl02" class="sidebar-logo" />
      </router-link>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      title: '杰纳医药',
      imgUrl01: require('@/assets/janus01.png'),
      imgUrl02: require('@/assets/janus02.png'),
    };
  },
};
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.sidebar-logo-container {
  position: relative;
  width: 100%;
  height: 50px;
  line-height: 50px;
  background: #191a23;
  text-align: center;
  overflow: hidden;

  & .sidebar-logo-link {
    height: 100%;
    width: 100%;

    & .sidebar-logo {
      width: 110px;
      height: 30px;
      vertical-align: middle;
      margin-right: 20px;
    }

    & .sidebar-collapse-logo {
      width: 32px;
      height: 32px;
      vertical-align: middle;
      margin-right: -3px;
      // margin-right: 6px;
    }

    & .sidebar-title {
      display: inline-block;
      margin: 0;
      width: 60px;
      height: 21px;
      font-size: 15px;

      font-weight: 500;
      color: rgba(255, 255, 255, 0.85);
      line-height: 21px;
      vertical-align: middle;
    }
  }

  &.collapse {
    .sidebar-logo {
      margin-right: 0px;
    }
  }
}
</style>
