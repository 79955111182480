<!--
 * @Description:
 * @Author: zby
 * @Date: 2021-11-25 10:18:58
 * @LastEditors: zby
 * @Reference:
-->
<template>
  <div class="choose-week">
    <!-- <span :class="$i18n.locale == 'en' ? 'choose-week__en_name' : 'choose-week__ch_name'">{{ $t('workRecord.currentWeek') }}</span> -->
    <div class="choose-week__granularity">
      <div class="last" @click="toLastWeek">
        <GLBgIcon
          class="bg-icon"
          :img="require('@/assets/workrecord/last.png')"
          :clickImg="require('@/assets/workrecord/last_click.png')"
          :hoverImg="require('@/assets/workrecord/last-h.png')"
        />
        <!-- {{ $t('workRecord.lastWeek') }} -->
      </div>
      <el-date-picker
        class="choose-week__core"
        v-model="weekTime"
        @change="setChooseValue"
        :picker-options="pickerOptions"
        type="week"
        :format="weekTimeFilter"
        placeholder="选择周"
        :clearable="false"
      >
      </el-date-picker>
      <div class="next" @click="toNextWeek">
        <GLBgIcon
          class="bg-icon"
          :img="require('@/assets/workrecord/next.png')"
          :clickImg="require('@/assets/workrecord/next_click.png')"
          :hoverImg="require('@/assets/workrecord/next-h.png')"
        />
        <!-- {{ $t('workRecord.nextWeek') }} -->
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentWeek, dayjs } from '@/utils/time-tools';
import getUrlParam from '@/utils/getUrlParam';

export default {
  name: 'ChooseWeek',
  components: {},
  props: {
    weekStartTime: {
      type: Number,
      default: +new Date(),
    },
    beginDate: {
      type: String,
      default: '',
    },
    endDate: {
      type: String,
      default: '',
    },
    pointDate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hrefTab: null,
      pickerOptions: {
        firstDayOfWeek: 1, //设置周一为起始点
      },
      weekTime: '', //仅用于设置周选择器的时间节点
      weekTimeFilter: '', //仅用以显示日期

      weekRange: {},
    };
  },
  created() {
    this.setChooseValue(this.weekStartTime, { firstSet: true });
  },
  /*   watch: {
    weekStartTime: {
      deep: true,
      immediate: false,
      handler(val) {
        console.log(val);
        this.setChooseValue(val, { firstSet: true });
      },
    },
  }, */

  methods: {
    getHrefTab() {
      const urlParam = getUrlParam();
      if (urlParam) {
        this.hrefTab = Object.prototype.hasOwnProperty.call(urlParam, 'tab') ? urlParam['tab'] : null;
      }
      return urlParam;
    },
    //默认本周weekTimeFilter：{startDateTime: "2021-11-22 00:00:00", endDateTime: "2021-11-28 23:59:59"}

    //选择周
    setChooseValue(v, { firstSet = false } = {}) {
      const urlParam = this.getHrefTab();
      let startTime = null;
      let endTime = null;
      if (urlParam && Object.keys(urlParam).length && firstSet) {
        startTime = urlParam.beginDate + ' 00:00:00';
        endTime = urlParam.endDate + ' 23:59:59';
        this.weekRange = { startTime, endTime };
      } else {
        let { startDateTime, endDateTime } = getCurrentWeek(v);
        startTime = startDateTime;
        endTime = endDateTime;
        this.weekRange = { startTime, endTime };
      }
      this.weekTimeFilter = `${startTime.split(' ')[0]}  ~  ${endTime.split(' ')[0]}`;
      const weekRangeData = JSON.parse(JSON.stringify(this.weekRange));
      this.$emit('getWeekRange', { weekRangeData, urlParam });

      if (firstSet) {
        this.weekTime = +dayjs(this.weekRange.startTime);
      }
    },

    toLastWeek() {
      this.weekTime = +dayjs(this.weekRange.startTime) - 1000;
      this.setChooseValue(this.weekTime, { firstSet: false });
    },

    toNextWeek() {
      this.weekTime = +dayjs(this.weekRange.endTime) + 2000;
      this.setChooseValue(this.weekTime, { firstSet: false });
    },
  },
};
</script>
<style lang="scss" scoped>
//选择周 开始
::v-deep.choose-week {
  display: flex;
  align-items: center;
  // .choose-week__ch_name {
  //   margin-right: 12px;
  // }
  // .choose-week__en_name {
  //   margin-right: 2px;
  // }
  // .choose-week_ch__core {
  //   width: 245px;
  //   .el-input__inner {
  //     color: #515a6e;
  //     height: 32px;
  //     line-height: 32px;
  //     text-align: center;
  //     padding: 0 10px;
  //   }
  //   .el-input__prefix {
  //     top: -3px;
  //   }
  // }
  .choose-week__granularity {
    display: flex;
    align-items: center;
    // margin-left: 20px;
    .last,
    .next {
      cursor: pointer;
    }
    .bg-icon {
      width: 23px;
      height: 18px;
      vertical-align: sub;
    }
    .last {
      margin-right: 10px;
    }
    .next {
      margin-left: 10px;
    }
    .choose-week__core {
      width: 230px;
      .el-input__inner {
        color: #515a6e;
        height: 32px;
        line-height: 32px;
        text-align: center;
        padding: 0 8px;
      }
      .el-input__prefix {
        top: -3px;
      }
    }
  }
}
//选择周 结束
</style>
