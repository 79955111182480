/* key用于路由跳转+鉴权的关键字所用，而name则用于正常的路由跳转识别功能 */
const staticRoutes = [
  {
    path: '/',
    redirect: '/work-record',
  },

  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true,
  },
  {
    path: '/loginCallback',
    component: () => import('@/views/login/callback'),
    hidden: true,
  },

  {
    path: '/work-record',
    name: 'WorkRecord',
    key: 'WorkRecord',
    component: () => import(/* webpackChunkName: "WorkRecord" */ '@/views/work-record/work-record'),
    meta: {
      keepAlive: true,
      title: 'WorkRecord',
      name: '首页',
      icon: 'icon-shouye',
      active_icon: 'icon-active-shouye-copy',
    },
  },

  {
    path: '/404',
    component: () => import('@/views/error-page/404'),
    hidden: true,
    meta: {
      title: 'wrongRoute',
      name: 'wrongRoute',
    },
  },

  {
    path: '/401',
    component: () => import('@/views/error-page/401'),
    hidden: true,
    meta: {
      title: '没有权限',
      name: '没有权限',
    },
  },
];

export default staticRoutes;
