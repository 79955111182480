import { BaseService } from '@/api/request';

const api = {};
// NaN undefined null过滤处理
// 注意，尽量不要过滤空字符串
const filterNull = json => {
  let newJson = {};
  let reg = RegExp(/NaN|undefined|null/);
  for (let li in json) {
    if (!reg.test(json[li])) {
      newJson[li] = json[li];
    }
  }
  return newJson;
};
/* 项目管理 */

/**
 * @description: 获取用户可管理的项目列表
 * @param  {*page 页码}
 * @param  {*pagesize 页面大小}
 */
api.getAdminProject = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/project/getAdminProject', params);
};

// 新的项目列表接口

api.getAdminProjectInProgress = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/projects', params);
};
/**
 * @description: 获取项目详情
 * @param  {*id 项目id}
 */
api.getProjectDetail = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/project/getProjectDetail', params);
};

/**
 * @description: 更新(保存)编辑后的项目详情
 */
api.getSaveProjectEdit = function (params = {}) {
  return BaseService.postRequest('api/pmf/v1/project/updateProject', params);
};
/**
 * @description: 更新项目预估数量
 */
api.updateEstimatedQuantity = function (id, params = {}) {
  return BaseService.putRequest('/api/pmf/v1/projects/' + id + '/estimateCount', params);
};

/**
 * @description: 按名字检索用户
 * @param  {*name 用户名}
 * @param  {*page 页码}
 * @param  {*pagesize 页面大小}
 */
api.getSearchUserName = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/user/getUser', params);
};
/**
 * @description: 按项目获取服务项

 */
api.getServiceByProject = function (params = '') {
  return BaseService.getRequest('api/pmf/v1/servings', params);
};
/**
 * @description: 按项目获取所有服务项（包括父项和子项）

 */
api.getServiceListByProject = function (params = '') {
  return BaseService.getRequest('api/pmf/v1/servings/list', params);
};
/**
 * @description: 获取服务项名称列表

 */
api.getServiceNames = function (params = '') {
  return BaseService.getRequest('api/pmf/v1/servings/names', params);
};
/**
 * @description: 按项目获取工作项

 */
api.getWorkDataByProject = function (params = '') {
  return BaseService.getRequest('api/pmf/v1/tasks', params);
};
/**
 * @description: 创建服务

 */
api.createService = function (params = {}) {
  return BaseService.postRequest('api/pmf/v1/service/createService', params);
};
/**
 * @description: 按项目保存服务项
 */
api.saveServiceItems = function (params = {}, id) {
  return BaseService.postRequest('/api/pmf/v1/projects/' + id + '/servings', params);
};

/**
 * @description: 按项目保存服务项明细
 */
api.saveServiceDetailItems = function (params = {}, id) {
  return BaseService.postRequest('/api/pmf/v1/servings/' + id + '/tasks', params);
};

/**
 * @description: 从服务项批量创建工作项
 */
api.batchCreateWorkItems = function (params = {}, id) {
  return BaseService.postRequest('api/pmf/v1/servings/' + id + '/tasks', params);
};
/**
 * @description: 批量更新工作项
 */
api.batchUpdateWorkItems = function (params = {}) {
  return BaseService.postRequest('api/pmf/v1/tasks/bulk', params);
};
/**
 * @description: 验证服务项是否有唯一值不符的情况
 */
api.verifyServiceItemTimeFilling = function (id) {
  return BaseService.getRequest('api/pmf/v1/servings/' + id + '/validateDelete');
};
api.getProjectTypeList = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/projects/projectTypes', params);
};
/**
 * @description: 删除工作项
 */
api.deleteWorkItem = function (id) {
  return BaseService.delRequest('api/pmf/v1/tasks/' + id);
};

/**
 * @description: 验证项目名称唯一性
 */
api.verifyUniqueness = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/projects/validateNameUniq', params);
};

api.getBo = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/projects/boApprovalDirector', params);
};
/**
 * @description: 导出表单
 */
api.exportOperateProject = function (params = {}, showMsg = false) {
  return BaseService.getRequest('api/pmf/v1/project/export', params, {
    otherRequestConfig: { responseType: 'blob' },
    showMsg,
  });
};
api.identifyServiceName = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/servings/validateNameUniq', filterNull(params));
};
api.getMatchList = function (id) {
  return BaseService.getRequest('/api/pmf/v1/projects/' + id + '/matchTaskServiceItems');
};

/**
 * @description: 上传和导出相关接口
 */
api.leadingInXlsx = function (params = {}, id, showMsg = false) {
  return BaseService.postRequest('api/pmf/v1/project/' + id + '/import/serving', params, { showMsg });
};
api.downloadDemoXlsx = function (params = {}, showMsg = false) {
  return BaseService.getRequest('api/pmf/v1/project/importTemplate/serving', params, { showMsg });
};
// 导出服务项
api.leadingServiceXlsx = function (params = {}, showMsg = false) {
  return BaseService.postRequest('api/pmf/v1/jobs/import', params, { showMsg });
};

/**
 * @description 项目进度
 */
api.getProjectProgress = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/schedules', params);
};
api.batchSaveProjectProgress = function (params = {}) {
  return BaseService.postRequest('api/pmf/v1/schedules', params);
};
/**
 * @description 批量编辑合同名称
 */
api.batchSaveContractNames = function (params = {}, id) {
  return BaseService.postRequest('/api/pmf/v1/projects/' + id + '/contractNames', filterNull(params));
};
/**
 * @description 设置服务项据实结算
 */
api.setJustSettlement = function (id, params = {}) {
  return BaseService.putRequest('/api/pmf/v1/servings/' + id + '/justSettlement', params);
};
/**
 * @description: 项目导入
 */
api.importProject = function (params = {}) {
  return BaseService.postRequest('api/pmf/v1/projects/import', params);
};
export default api;
