<!--
 * @description 基于el-select进行的单选框封装
 * @param { value } name description...这里接收value的值，再利用change方法传送出去，完成v-model双向绑定操作
 * @return { Boolean } description...
 -->

<template>
  <el-select
    class="mySelect"
    :size="size"
    :filterable="filterable"
    @change="change"
    @visible-change="pullDownControl"
    :reserve-keyword="reserveKeyword"
    v-select-loadmore:rangeNum="selectLoadMore"
    :value="value"
    :collapse-tags="collapseTags"
    :placeholder="placeholder"
    @clear="handleSelectClear"
    clearable
    :popper-append-to-body="false"
    :multiple="multiple"
    :filter-method="filterData"
    :disabled="isDisabled"
  >
    <template v-if="isLoadMore">
      <el-option
        v-for="item in originalOptions.slice(0, rangeNumber)"
        :key="item[optionValue]"
        :label="$t(item[optionLabel])"
        :value="item[optionValue]"
        class="options"
      >
        <el-popover v-if="$t(item[optionLabel]).length >= defaultOptionLabel && pullDown" placement="top-start" trigger="hover">
          <p>{{ $t(item[optionLabel]) }}</p>
          <span slot="reference">{{ $t(item[optionLabel]).slice(0, defaultOptionLabel - 3) + '...' }}</span>
        </el-popover>
        <span v-else>{{ $t(item[optionLabel]) }}</span>
      </el-option>
    </template>
    <template v-else>
      <el-option v-for="item in originalOptions" :key="item[optionValue]" :label="$t(item[optionLabel])" :value="item[optionValue]" class="options">
        <el-popover v-if="$t(item[optionLabel]).length >= defaultOptionLabel && pullDown" placement="top-start" trigger="hover">
          <p>{{ $t(item[optionLabel]) }}</p>
          <span slot="reference">{{ $t(item[optionLabel]).slice(0, defaultOptionLabel - 3) + '...' }}</span>
        </el-popover>
        <span v-else>{{ $t(item[optionLabel]) }}</span>
      </el-option></template
    >
  </el-select>
</template>

<script>
export default {
  name: 'MySelect',
  props: {
    defaultOptionLabel: {
      type: Number,
      default: 40,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    // 可选option中哪个值作为value
    optionValue: {
      type: String,
      default: 'id',
    },
    collapseTags: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'mini',
    },
    filterable: {
      type: Boolean,
      default: true,
    },
    value: {
      type: [String, Number, Array],
    },
    reserveKeyword: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    // 下拉加载开关，默认开
    isLoadMore: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: '请选择',
    },
    //optionVal options里面的值
    optionVal: {
      type: [Number, String],
      default: 'optionVal',
    },
    optionLabel: {
      type: String,
      default: 'name',
    },
    //区分不同options的标识参数
    label: {
      type: String,
      default: '',
    },
  },
  watch: {
    options: {
      deep: true,
      immediate: true,
      handler(val) {
        this.originalOptions = Object.assign([], val);
      },
    },
  },
  data() {
    return {
      pullDown: false,
      // 默认搜索项
      text: '',
      // 下拉列表数据
      originalOptions: [],
      // 默认显示数量
      rangeNumber: 50,
    };
  },
  methods: {
    handleSelectClear() {
      // 清空后重置列表
      this.originalOptions = Object.assign([], this.options);
      this.rangeNumber = 50;
    },
    change(val) {
      let chooseVal = this.originalOptions.find(item => {
        return item[this.optionValue] === val;
      });
      this.$emit('input', val, chooseVal, this.label);
    },
    pullDownControl(val) {
      this.pullDown = val;
    },
    filterData(query) {
      this.originalOptions = Object.assign([], this.options);
      if (query) {
        this.originalOptions = this.originalOptions.filter(item => {
          if (item.name.includes(query)) {
            return item;
          }
        });
      }
    },
    // 暂时不需要远程搜索，待应用时再完善代码
    // remoteMethod(query, checkedData) {
    //   this.text = query;
    //   this.loading = true;
    //   setTimeout(() => {
    //     this.loading = false;
    //     //从完整数据中筛选所匹配项
    //     let arr = this.options.filter(item => {
    //       return item.username.toLowerCase().indexOf(query.toLowerCase()) > -1;
    //     });
    //     //获取已经选择的数据防止因数据初始化匹配不到显示为ID
    //     let initUserList = checkedData ? this.initUserSelectData(checkedData) : [];
    //     //删除搜索列表相同ID，并重新赋值
    //     this.originalOptions = [...initUserList, ...arr.filter(item => !initUserList.some(x => x.userId === item.userId))];
    //   }, 80);
    // },
    // 解决回显时显示为id的问题
    // initUserSelectData(arr) {
    //   //获取当前所选值
    //   if (this.multiple) {
    //     return this.options.filter(item => arr.some(x => x === item.userId)); // 选中的值 数组
    //   }
    //   return this.options.filter(item => arr === item.userId); // 选中的值 数组
    // },
    //     filterList(query = '') {
    //   console.log(query);

    //   this.originalOptions = Object.assign([], this.options);
    //   if (query) {
    //     this.originalOptions = this.originalOptions.filter(item => {
    //       if (item.label.includes(query)) {
    //         return item;
    //       }
    //     });
    //   }
    // },
    // 下拉框滚动触发添加待选数据
    selectLoadMore() {
      if (this.isLoadMore) {
        this.rangeNumber += 50;
        this.originalOptions.slice(0, this.rangeNumber);
      }
    },
  },
};
</script>

<style lang="scss">
.mySelect ::-webkit- {
  /*滚动条整体样式*/
  width: 0 !important;
  background-color: transparent !important;
  border-radius: 0 !important;
}
.options {
  // width: 350px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
