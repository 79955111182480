/** * @description 针对项目工时导出的组件封装 * @param { Boolean } name description... * @return { Boolean } description... */

<template>
  <div id="project-export">
    <SimpleDialog
      class="export_project"
      title="导出工时数据"
      :visible.sync="dialogExportVisible"
      width="620px"
      :confirmBtnText="confirmBtnText"
      :confirmBtnLoading="exportLoading"
      showConfirm
      showCancel
      @onCancel="exportDialogClose"
      @onSubmit="exportDialogSubmit"
      :before-close="exportDialogClose"
    >
      <el-form :model="dialogExportForm" ref="dialogExportForm" :rules="exportRules" :label-position="labelPosition" label-width="150px">
        <el-form-item prop="date" label="请选择时间范围" class="formLabel">
          <date-pick ref="datePick" @onChange="dataView" startDate="2022-01-01" :clearable="false" size="medium" />
        </el-form-item>
        <el-form-item label="导出数据类型" prop="treeDatas" class="formLabel">
          <el-tree
            class="projTree"
            :data="dialogExportForm.treeDatas"
            show-checkbox
            node-key="id"
            ref="tree"
            :default-expanded-keys="[1]"
            :default-checked-keys="[1]"
            :props="defaultProps"
            @check="handleNodeClick"
          ></el-tree>
        </el-form-item>
      </el-form>
    </SimpleDialog>
  </div>
</template>

<script>
import projectManagementApi from '@/api/modules/projectManagement.api.js';
import responseCheck from '@/utils/saveAs';
export default {
  name: 'ProJExport',

  props: {
    exportTree: {
      type: Array,
      default() {
        return [];
      },
    },
    treeData: {
      type: Array,
      default() {
        return [];
      },
    },
    dialogExportVisible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const validateTree = (rule, value, callback) => {
      let arr = this.$refs.tree.getCheckedKeys();
      if (arr.length === 0 || !arr) {
        callback(new Error('请至少选择一个数据类型'));
      } else {
        callback();
      }
    };
    const validateDate = (rule, value, callback) => {
      if (value.length < 2 || !value) {
        callback(new Error('请选择日期'));
      } else {
        callback();
      }
    };
    return {
      confirmBtnText: '确定导出',
      exportLoading: false,
      labelPosition: 'right',
      // 导出弹窗
      dialogExportForm: {
        treeDatas: this.treeData,
        date: [],
        /*         startKey: '',
        endKey: '', */
      },
      exportRules: {
        treeDatas: [{ type: 'array', required: true, validator: validateTree, trigger: 'change' }],
        date: [{ type: 'array', required: true, validator: validateDate, trigger: 'change' }],
      },

      defaultProps: {
        children: 'children',
        label: 'label',
      },
    };
  },

  methods: {
    handleNodeClick() {
      let checkedKeys = arguments[1].checkedKeys;
      if (checkedKeys.length === 0 || !checkedKeys) {
        return;
      }
      this.exportTree = [];
      this.dialogExportForm.treeDatas.forEach(item => {
        checkedKeys.includes(item.id) ? this.exportTree.push(item.value) : null;
        if (item.children) {
          item.children.forEach(v => {
            checkedKeys.includes(v.id) ? this.exportTree.push(v.value) : null;
          });
        }
      });
    },
    dataView(list) {
      this.dialogExportForm.date = [];
      if (list && list.length > 0) {
        this.dialogExportForm.date.push(list[0], list[1]);
      }
    },
    exportDialogSubmit() {
      let exportCorrect = false;
      const query = {
        dataTypes: this.exportTree.toString(),
        beginDate: this.dialogExportForm.date[0],
        endDate: this.dialogExportForm.date[1],
      };
      this.$refs.dialogExportForm.validate(async valid => {
        if (valid) {
          try {
            this.confirmBtnText = '导出中';
            this.exportLoading = true;
            const res = await projectManagementApi.exportOperateProject(query);
            responseCheck(res[2]);
          } catch (err) {
            exportCorrect = true;
            this.exportLoading = false;
            this.confirmBtnText = '确定导出';
            this.$message.error('导出项目失败');
          }
        } else {
          return false;
        }
        if (!exportCorrect) {
          this.exportDialogClose();
        }
        this.exportLoading = false;
        this.confirmBtnText = '确定导出';
      });
    },
    exportDialogClose() {
      this.$emit('closeDiago', false);
      this.dialogExportForm.date = [];
      // 用于清空日期组件本身绑定的值
      this.$refs.datePick.date = [];
      this.$refs.dialogExportForm.resetFields();
    },
  },
};
</script>

<style lang="scss">
.export_project {
  .el-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 34px 55px;
    .formLabel {
      margin: 15px 0;
    }

    .el-form-item__label {
      font-size: 16px;
      font-weight: 400;
      color: #515a6e;
    }

    .el-form-item {
      margin-bottom: 0;
    }
    .projTree {
      margin-top: 6px;
    }
    .projectname {
      .el-input {
        width: 372px;
        border-radius: 4px;
        height: 40px;
      }
    }

    .director {
      margin-top: 24px;
      .el-select {
        width: 372px;
        height: 40px;
        border-radius: 4px;
      }
    }

    .chief {
      margin-top: 24px;
      .el-select {
        width: 372px;
        height: 40px;
        border-radius: 4px;
      }
    }
  }
}
</style>
