<template>
  <div class="tabPane-container" ref="tabPane">
    <nav class="el-tab">
      <div
        v-for="(item, index) in tabList"
        :key="item.name"
        :name="item.name"
        class="tabItem"
        :ref="`id${index}`"
        :id="`id${index}`"
        :class="{ active: activeValue.name === item.name }"
        @click="handleClick(item)"
      >
        <p class="title">{{ $t(item.label) }}</p>
      </div>
      <div
        class="bg"
        v-if="showBg"
        :style="`height:52px;width:${activeValue.width - 28.5}px;transform: translateX(${activeValue.startPoint - 3}px);`"
      />
    </nav>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'TabPane',
  props: {
    activeIndex: {
      type: Number,
      default: 0,
    },
    tabList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      activeValue: { name: '', index: 0 },
      tabHeight: null,
      showBg: false,
    };
  },
  created() {
    this.handleClick(this.tabList[this.activeIndex]);
  },
  mounted() {
    let startPoint = 0;

    this.tabList.forEach((item, index) => {
      let width = this.$refs[`id${index}`][0].clientWidth;
      Object.assign(item, { index, startPoint, width });
      startPoint += width;
    });
    this.showBg = true;
  },
  watch: {
    activeIndex(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.handleClick(this.tabList[this.activeIndex]);
      }
    },
  },
  methods: {
    handleClick(item) {
      this.activeValue = item;
      this.$emit('isActive', this.activeValue.name);
    },
  },
};
</script>

<style lang="scss" scoped>
.tabPane-container {
  margin: 0 24px;
  background: #f8f8f9;
  overflow: hidden;
  .el-tab {
    position: relative;
    width: 100%;
    background: #fff;
    cursor: pointer;
    display: flex;
    flex-wrap: nowrap;
    flex: 1;
    justify-content: flex-start;
    padding-left: 35px;

    .tabItem {
      position: relative;
      display: flex;

      height: 52px;
      background: #fff;
      font-size: 16px;
      padding-right: 37px;
      transition-property: all;
      transition-duration: 0.5s;
      color: #515a6e;
      .title {
        font-weight: 500;
        display: inline-block;
        margin: 0 auto;
        line-height: 52px;
      }
    }
    .active {
      color: #2d8cf0;
    }
    .bg {
      position: absolute;
      top: 0;
      z-index: 1;
      border-bottom: 3px #1890ff solid;
      transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      list-style: none;
    }

    .el-tab-pane:last-child {
      margin-right: 0;
    }
  }
  .el-tab::after {
    position: absolute;
    top: 52px;
    transform: translate(-50%);
    left: 50%;
    content: '';
    width: calc(100% - 60px);
    height: 1px;
    background: #e8eaec;
  }
}
</style>
