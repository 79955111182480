import { getLanguage } from '@/lang/index';
import mutations from '../mutations';
import * as types from '../mutation-types';
const state = {
  dialogVisible: false, //右上角个人信息弹窗
  toolBarData: [], //保存标签button的数组
  cacheView: [], //保存需要缓存的数组(需要缓存的路由)
  sidebar: {
    opened: localStorage.getItem('sidebarStatus') ? !!+localStorage.getItem('sidebarStatus') : true,
    width: 0,
    withoutAnimation: false,
  },
  device: 'desktop',
  userConfig: {},
  language: getLanguage(),
};
const actions = {
  commitToolBar({ commit }, data) {
    commit(types.SETOOLDATA, data);
    commit(types.SETCACHEVIEW, data);
  },
  clearToolBar({ commit }, data) {
    commit(types.CLEARTOOLITEM, data.detail);
  },
  clearCache({ commit }, data) {
    commit(types.CLEARCACHEVIEW, data);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
