import axios from 'axios';
import Qs from 'qs';
import { Message } from 'element-ui';
import { getToken } from '@/utils/auth';
// import responseCheck from '@/utils/saveAs';

import handErrCode from './handlingErrorCode ';

const BaseServiceApi = process.env.VUE_APP_SERVICE_BASE_URL;
// 一个axios请求实例
const BaseService = axios.create({
  baseURL: BaseServiceApi, // url = base url + request url
  timeout: 20000, // request timeout
});

let g_showMsg = []; //记录需要错误提示的接口
// 导出时需要在相应的接口信息为otherRequestConfig添加 responseType:'blob'，以blob数据流的形式下载文件
BaseService.getRequest = async (url, params = {}, { showMsg = true, otherRequestConfig = {} } = {}) => {
  showMsg && !g_showMsg.includes(url) && g_showMsg.push(url);
  try {
    const res = await BaseService.get(url, { params, ...otherRequestConfig });
    // 针对导出进行判断
    return [res?.data?.data, res?.data, res];
  } catch (error) {
    // 200的时候data必定有值，非200的情况下必定无值
    return [null, error?.data, error];
  }
};

BaseService.postRequest = async (url, params = {}, { showMsg = true, isJson = true, otherRequestConfig = {} } = {}) => {
  showMsg && !g_showMsg.includes(url) && g_showMsg.push(url);
  try {
    const res = await BaseService.post(url, isJson ? params : Qs.stringify(params), { ...otherRequestConfig });
    return [res?.data?.data, res?.data, res];
  } catch (error) {
    return [null, error?.data, error];
  }
};
BaseService.patchRequest = async (url, params = {}, { showMsg = true, isJson = true, otherRequestConfig = {} } = {}) => {
  showMsg && !g_showMsg.includes(url) && g_showMsg.push(url);
  try {
    const res = await BaseService.patch(url, isJson ? params : Qs.stringify(params), { ...otherRequestConfig });
    return [res?.data?.data, res?.data, res];
  } catch (error) {
    return [null, error?.data, error];
  }
};
BaseService.delRequest = async (url, params = {}, { showMsg = true } = {}) => {
  showMsg && !g_showMsg.includes(url) && g_showMsg.push(url);
  try {
    const res = await BaseService.delete(url, { params });
    return [res?.data?.data, res?.data, res];
  } catch (error) {
    return [null, error?.data, error];
  }
};
BaseService.putRequest = async (url, otheConfig = undefined, params = {}, { showMsg = true, isJson = true } = {}) => {
  showMsg && !g_showMsg.includes(url) && g_showMsg.push(url);
  try {
    const res = await BaseService.put(url, otheConfig, isJson ? params : Qs.stringify(params));
    return [res?.data?.data, res?.data, res];
  } catch (error) {
    return [null, error?.data, error];
  }
};

BaseService.restfulRequest = async (url, method, params = {}) => {
  let config = {
    url,
    method,
  };
  if (method === 'get') {
    config.params = { ...params };
  }
  if (method !== 'get') {
    config.data = { ...params };
  }
  try {
    // const res =
    return await BaseService.request(config);
    // return [res?.data, res];
  } catch (error) {
    return [error?.data?.data, error?.data, error];
  }
};

// 添加请求拦截器
BaseService.interceptors.request.use(
  config => {
    //后续可以在这进行token的传递
    config.headers['Authorization'] = getToken();
    // 请求发送前进行处理
    // console.log('请求拦截BaseService', config);
    return config;
  },
  error => {
    // 请求错误处理
    // console.log(error);
    // return error;
    return Promise.reject(error);
  }
);

// 添加响应拦截器
BaseService.interceptors.response.use(
  response => {
    // const res = response.data;
    // console.log('响应拦截BaseService', response);
    return response;
  },
  error => {
    // 响应错误处理
    //通知错误信息
    // console.log(g_showMsg);

    if (g_showMsg.includes(error.config.url) && !error.response.data.reason) {
      Message({
        message: error.response?.data?.message || error.message || '请求错误',
        type: 'error',
        duration: 5 * 1000,
      });
    }
    //处理错误信息
    error?.response?.status ? handErrCode(error.response.status) : null;
    /*     console.log(error.request);
    console.log(error.response);
    console.log(error.message);
    console.log(error.config); */
    // return error;
    return Promise.reject(error.response);
  }
);

//----------------------------------下一个服务器请求实例----------------------------------

export { BaseService };
