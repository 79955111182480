<template>
  <div class="toolbar">
    <div class="bar-icon" @click="scrolltoDestion('left')">
      <GLBgIcon class="left-cov" :img="require('@/assets/tabIcon/left-cov.png')" :clickImg="require('@/assets/tabIcon/left-cov.png')" />
    </div>
    <div class="tags-zone" ref="tagsScroll">
      <el-tag
        class="toolItem"
        type="info"
        :disable-transitions="false"
        :closable="item.id != 0"
        effect="plain"
        v-for="(item, index) in getToolData"
        :key="index"
        :class="{ active: $route.path == item.detail }"
        @click="redirect(item)"
        @close="closeToolItem(item)"
      >
        <span class="dot" v-if="$route.path == item.detail" :class="{ dotActive: $route.path == item.detail }"></span>
        <p class="tab-name">{{ $t('route.' + item.componentName) }}</p>
      </el-tag>
    </div>
    <div class="bar-icon" @click="scrolltoDestion('right')">
      <GLBgIcon class="right-cov" :img="require('@/assets/tabIcon/right-cov.png')" :clickImg="require('@/assets/tabIcon/right-cov.png')" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TooBar',
  computed: {
    ...mapGetters(['getToolData']),
  },

  methods: {
    scrolltoDestion(des) {
      if (des === 'left') {
        this.$refs.tagsScroll.scrollTo({
          left: 0,
          top: 0,
          behavior: 'smooth',
        });
      } else {
        console.log(2);

        this.$refs.tagsScroll.scrollTo({
          left: this.$refs.tagsScroll.clientWidth,
          top: 0,
          behavior: 'smooth',
        });
      }
    },
    closeToolItem(item) {
      this.$store.commit('app/SETPERSONALDATA', false);
      if (this.getToolData.length <= 1) {
        return;
      }
      this.$store.dispatch('app/clearToolBar', item);
      this.$store.dispatch('app/clearCache', item.componentName);
    },
    redirect(item) {
      this.$store.commit('app/SETPERSONALDATA', false);
      if (item.componentName === this.$route.name) {
        return;
      }
      this.$router.push({ path: item.detail });
    },
  },
};
</script>

<style lang="scss" scoped>
.toolbar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 !important;
  margin: 0 28px;
  // overflow-x: scroll;
  line-height: 50px;
  .bar-icon {
    cursor: pointer;
    width: 8px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 11px;
    .left-cov {
      width: 6px;
      height: 12px;
      display: inline-block;
    }
    .right-cov {
      width: 6px;
      height: 12px;
      display: inline-block;
    }
  }
  .tags-zone {
    overflow: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    .toolItem {
      height: 32px;

      align-items: center;
      padding: 0;
      position: relative;
      border: none;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      margin: 0;
      margin-right: 6px;
      cursor: pointer;

      background: #ffffff;
      border-radius: 2px;
      text-align: left;
      .tab-name {
        display: inline-block;
        margin: 0;
        padding: 1px 30px 0 10px;
        font-size: 13px;
      }
      ::v-deep .el-tag__close {
        position: absolute;
        display: inline-block;
        height: 15px;
        right: 9px;
        top: 8.5px;
      }
      ::v-deep .el-tag__close:hover {
        position: absolute;
        right: 9px;
        top: 8.5px;
        content: '';
        height: 15px;
        border-radius: 50%;
        background-size: 100%;
        background-position-y: 1152px;
      }
    }
  }
  ::v-deep .tags-zone::-webkit-scrollbar {
    display: none !important;
  }
  .active {
    background-color: #ffffff;

    color: #2d8cf0;
    ::v-deep .el-tag__close {
      color: #808695;
    }
  }
  .dotActive {
    color: #808695;
  }
}
</style>
