import Container from '@/layout/router-container.vue';
const dynamicRoutes = [
  {
    path: '/special-workingHours',
    // name: 'PersonalWorking',
    key: 'SpecialWorkingHours',
    component: () => import(/* webpackChunkName: "PersonalWorking" */ '@/views/special-workingHours/index'),
    meta: {
      keepAlive: false,
      title: 'specialWorkingHours',
      name: '特殊工时填报',
      icon: 'icon-teshugongshitianbao',
      active_icon: 'icon-active-teshugongshitianbao-copy',
    },
  },
  {
    path: '/personal-working',
    // name: 'PersonalWorking',
    key: 'PersonalWorking',
    component: () => import(/* webpackChunkName: "PersonalWorking" */ '@/views/personal-working/personal-working'),
    meta: {
      keepAlive: false,
      title: 'PersonalWorking',
      name: '个人工时统计',
      icon: 'icon-gerengongshitianbao',
      active_icon: 'icon-active-gerengongshitianbao-copy',
    },
  },
  {
    path: '/audit',
    // name: 'PersonalWorking',
    key: 'AuditManagement',
    component: () => import('@/views/audit/index'),
    meta: {
      keepAlive: false,
      title: 'AuditManagement',
      name: '工时审核',
      icon: 'icon-gongshishenhe1',
      active_icon: 'icon-gongshishenhe1-copy',
    },
  },
  {
    path: '/project-management',
    // name: 'ProjectManagement',
    key: 'ProjectManagement',
    component: Container,

    meta: {
      title: 'ProjectManagement',
      icon: 'icon-xiangmuguanli',
      active_icon: 'icon-active-xiangmuguanli-copy',
    },
    children: [
      {
        path: 'project-statement',
        // name: 'SponsorManagement',
        key: 'ProjectManagement',
        component: () => import(/* webpackChunkName: "ProjectManagement" */ '@/views/project-management/project-statement'),
        meta: {
          keepAlive: true,
          name: '项目管理',
          title: 'ProjectStatement',
          parentPath: '/project-management',
        },
      },
      {
        path: 'schedule-management',
        // name: 'ScheduleManagement',
        key: 'ScheduleManagement',
        component: () => import(/* webpackChunkName: "ScheduleManagement" */ '@/views/project-management/schedule-management'),
        meta: {
          keepAlive: true,
          name: '项目进度',
          title: 'ScheduleManagement',
          parentPath: '/project-management',
          icon: '',
        },
      },
    ],
  },

  {
    path: '/operation-management',
    // name: 'OperationManagement',
    key: 'OperationManagement',
    component: () => import(/* webpackChunkName: "OperationManagement" */ '@/views/operation-management/operation-management'),
    meta: {
      keepAlive: true,
      title: 'OperationManagement',
      name: '运营管理',
      icon: 'icon-yunyingguanli',
      active_icon: 'icon-active-yunyingguanli-copy',
    },
  },
  {
    path: '/contract-management',
    // name: 'OperationManagement',
    key: 'ContractManagement',
    component: () => import(/* webpackChunkName: "OperationManagement" */ '@/views/contract-management/index'),
    meta: {
      keepAlive: true,
      title: 'ContractManagement',
      name: '合同管理',
      icon: 'icon-hetongguanli',
      active_icon: 'icon-hetongguanli-copy',
    },
  },
  // {
  //   path: '/public-affairs',
  //   // name: 'PublicAffairs',
  //   key: 'PublicAffairs',
  //   component: () => import(/* webpackChunkName: "PublicAffairs" */ '@/views/public-affairs/public-affairs'),
  //   meta: {
  //     keepAlive: true,
  //     title: 'PublicAffairs',
  //     name: '公共事项',
  //     icon: 'icon-gonggongshixiangguanli',
  //     active_icon: 'icon-active-gonggongshixiangguanli-copy',
  //   },
  // },

  {
    path: '/public-management',
    // name: 'PublicManagement',
    key: 'PublicManagement',
    component: () => import(/* webpackChunkName: "PublicManagement" */ '@/views/public-management/public-management'),
    meta: {
      keepAlive: true,
      title: 'PublicManagement',
      name: '公共事项管理',
      icon: 'icon-gonggongshixiangguanli',
      active_icon: 'icon-active-gerengongshitianbaogonggongshixiangguanli-copy',
    },
  },
  {
    path: process.env.VUE_APP_BI_SERVICE_BASE_URL,
    key: 'BIManagement',
    meta: {
      title: 'BIManagement',
      name: 'BI数据报表',
      icon: 'icon-BIshujubaobiao',
      active_icon: 'icon-BIshujubaobiao-copy',
    },
  },
  {
    path: '/project-list',
    // name: 'ProjectList',
    key: 'ProjectList',
    component: () => import(/* webpackChunkName: "ProjectList" */ '@/views/project-data/project-list/index'),
    meta: {
      keepAlive: true,
      title: 'ProjectList',
      name: '项目列表',
      icon: 'icon-xiangmushujuhuizong',
      active_icon: 'icon-active-xiangmushujuhuizong-copy',
    },
  },
  /*   {
    path: '/project-data',
    // name: 'ProjectData',
    key: 'ProjectData',
    component: Container,
    meta: {
      keepAlive: true,
      title: 'ProjectData',
      name: '项目数据汇总',
      icon: 'icon-xiangmushujuhuizong',
      active_icon: 'icon-active-xiangmushujuhuizong-copy',
    },
    children: [
      {
        path: 'project-statement',
        // name: 'ProjectStatement',
        key: 'ProjectStatement',
        component: () => import('@/views/project-data/project-statement/project-statement.vue'),
        meta: {
          keepAlive: true,
          title: 'ProjectStatement',
          name: '项目报表',
          parentPath: '/project-data',
          icon: '',
        },
      },
      {
        path: 'project-list',
        // name: 'ProjectList',
        key: 'ProjectList',
        component: () => import('@/views/project-data/project-list/project-list.vue'),
        meta: {
          keepAlive: true,
          title: 'ProjectList',
          name: '项目列表',
          parentPath: '/project-data',
          icon: '',
        },
      },
    ],
  }, */

  {
    path: '/resource-management',
    // name: 'ResourceManagement',
    key: 'ResourceManagement',
    component: Container,
    meta: {
      title: 'ResourceManagement',
      icon: 'icon-ziyuanguanli',
      active_icon: 'icon-active-ziyuanguanli-copy',
    },
    children: [
      {
        path: 'sponsor-management',
        // name: 'SponsorManagement',
        key: 'SponsorManagement',
        component: () => import(/* webpackChunkName: "SponsorManagement" */ '@/views/resource-management/sponsor-management.vue'),
        meta: {
          keepAlive: true,
          title: 'SponsorManagement',
          name: '申办方维护',
          parentPath: '/resource-management',
          icon: '',
        },
      },
      {
        path: 'client-management',
        // name: 'ClientManagement',
        key: 'ClientManagement',
        component: () => import(/* webpackChunkName: "ClientManagement" */ '@/views/resource-management/client-management.vue'),
        meta: {
          keepAlive: true,
          title: 'ClientManagement',
          name: '客户维护',
          parentPath: '/resource-management',
          icon: '',
        },
      },
      {
        path: 'plan-management',
        // name: 'PlanManagement',
        key: 'PlanManagement',
        component: () => import(/* webpackChunkName: "PlanManagement" */ '@/views/resource-management/plan-management.vue'),
        meta: {
          keepAlive: true,
          title: 'PlanManagement',
          name: '方案维护',
          parentPath: '/resource-management',
          icon: '',
        },
      },
      {
        path: 'service-management',
        // name: 'SponsorManagement',
        key: 'ServiceManagement',
        component: () => import(/* webpackChunkName: "SponsorManagement" */ '@/views/resource-management/service-management.vue'),
        meta: {
          keepAlive: true,
          title: 'ServiceManagement',
          name: '服务项维护',
          icon: '',
        },
      },
    ],
  },

  {
    path: '/system-config',
    // name: 'SystemConfig',
    key: 'SystemConfig',
    component: Container,
    meta: {
      title: 'SystemConfig',
      icon: 'icon-xitongguanli',
      active_icon: 'icon-active-xitongguanli-copy',
    },
    children: [
      {
        path: 'department-management',
        // name: 'DepartmentManagement',
        key: 'DepartmentManagement',
        component: () => import(/* webpackChunkName: "DepartmentManagement" */ '@/views/system-config/department-management/index.vue'),
        meta: {
          keepAlive: true,
          title: 'DepartmentManagement',
          name: '部门管理',
          icon: '',
        },
      },
      {
        path: 'role-management',
        // name: 'RoleManagement',
        key: 'RoleManagement',
        component: () => import(/* webpackChunkName: "RoleManagement" */ '@/views/system-config/role-management/index.vue'),
        meta: {
          keepAlive: true,
          title: 'RoleManagement',
          name: '角色管理',
          icon: '',
        },
      },
      {
        path: 'user-management',
        // name: 'UserManagement',
        key: 'UserManagement',
        component: () => import(/* webpackChunkName: "UserManagement" */ '@/views/system-config/user-management/index.vue'),
        meta: {
          keepAlive: true,
          title: 'UserManagement',
          name: '用户管理',
          icon: '',
        },
      },
    ],
  },
];

export default dynamicRoutes;
