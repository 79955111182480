export default function getUrlParam() {
  const url = document.location.toString();
  const arrUrl = url.split('//');
  const start = arrUrl[1].lastIndexOf('/');
  let relUrl = arrUrl[1].substring(start);
  if (relUrl.indexOf('?') != -1) {
    relUrl = relUrl.split('?')[1];
    const arr = relUrl.split('&');
    const result = {};
    for (let i = 0; i < arr.length; i++) {
      const item = arr[i].split('=');
      result[item[0]] = item[1];
    }
    return result;
  }
}
