<template>
  <keep-alive> <router-view> </router-view></keep-alive>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'routerContainer',
  data() {
    return {
      cache: [],
    };
  },
  created() {
    if (!this.getCacheView.includes('routerContainer')) {
      this.$store.commit('app/setCacheView', { componentName: 'routerContainer', alive: true });
    }
  },
  computed: {
    ...mapGetters(['getCacheView']),
  },
};
</script>
