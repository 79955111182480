<!--
 * @Description: 简易弹出框 二次封装
 * @Author: zby
 * @Date: 2021-08-25 15:58:45
 * @LastEditors: guangyuan.hu guangyuan.hu
 * @Reference: 其它el-dialog参数见https://element.eleme.cn/#/zh-CN/component/dialog
-->
<template>
  <el-dialog
    v-bind="$attrs"
    v-on="$listeners"
    class="gl_dialog"
    :width="width"
    :height="height"
    :append-to-body="appendFront"
    :before-close="handleDialogClose"
    :lock-scroll="lockScroll"
    :modal="modal"
    :close-on-click-modal="modalCloseBackground"
    :modal-append-to-body="modalAppendFront"
  >
    <template slot="title">
      <div class="slotHeader" :class="{ slotHeaderBorder: headerBorder }" :style="{ 'text-align': headerAlign }">
        <div class="slotTitle">
          {{ title }}
        </div>
      </div>
    </template>
    <slot class="formContaniner"></slot>
    <div v-if="showConfirm || showCancel" slot="footer" class="footer">
      <el-button v-if="showCancel" class="closebtn" @click="$emit('onCancel')" :loading="cancelBtnLoading">{{ cancelBtnText }}</el-button>
      <el-button v-if="showConfirm" type="primary" @click="$emit('onSubmit')" :loading="confirmBtnLoading">{{ confirmBtnText }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'SimpleDialog',
  inheritAttrs: false,
  props: {
    //:visible.sync="adminDialog"显示隐藏 外部
    title: {
      type: String,
      default: '提示',
    },
    headerAlign: {
      type: String,
      default: 'center',
    },
    width: String,
    height: String,
    lockScroll: {
      type: Boolean,
      default: false,
    },
    //显示确认按钮$emit('onSubmit')
    showConfirm: {
      type: Boolean,
      default: false,
    },

    headerBorder: {
      type: Boolean,
      default: true,
    },
    modal: {
      type: Boolean,
      default: true,
    },
    modalCloseBackground: {
      type: Boolean,
      default: false,
    },
    modalAppendFront: {
      type: Boolean,
      default: true,
    },

    confirmBtnText: {
      type: String,
      default: '确认',
    },
    appendFront: {
      type: Boolean,
      default: false,
    },
    confirmBtnLoading: {
      type: Boolean,
      default: false,
    },
    //取消确认按钮$emit('onCancel')
    showCancel: {
      type: Boolean,
      default: false,
    },
    cancelBtnText: {
      type: String,
      default: '取消',
    },
    cancelBtnLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  //生命周期 - 创建完成（访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
  methods: {
    handleDialogClose() {
      this.$emit('onCancel');
    },
  },
};
</script>
<style lang="scss" scoped>
.gl_dialog ::v-deep.el-dialog {
  border-radius: 8px;
  overflow: hidden;
  margin-top: 11vh !important;
  .el-dialog__header {
    box-sizing: border-box;
    height: 60px;
    padding: 24px 18px;
    border-bottom: 1px solid #eff0f4;
    display: flex;
    justify-content: center;
    align-items: center;
    .el-dialog__title {
      font-weight: 600;
      color: #17233d;
      font-size: 16px;
    }
    .el-dialog__headerbtn {
      background-color: white;
      font-size: 14px;
      .el-dialog__close {
        color: #000;
        font-weight: 1000;
      }
    }
  }
  .slotHeader {
    box-sizing: border-box;
    height: 60px;
    line-height: 60px;
    padding-left: 0;
    .slotTitle {
      font-weight: 600;
      color: #17233d;
      font-size: 16px;
    }
    .slotHeaderbtn {
      background-color: white;
      font-size: 14px;
      .el-dialog__close {
        color: #000;
        font-weight: 1000;
      }
    }
  }
  .slotHeaderBorder {
    border-bottom: 1px solid #eff0f4;
  }
  .el-dialog__body {
    padding: 0;
  }

  .el-dialog__footer {
    padding: 12px;
    padding-top: 32px;
    padding-right: 32px;
    padding-bottom: 20px;
    background: #ffffff;
    .footer {
      display: flex;
      justify-content: flex-end;
      .el-button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        margin: 0;
        min-width: 64px;
        height: 28px;
        line-height: 28px;
        position: relative;
        /*        span {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        } */
      }
      .closebtn {
        margin-right: 16px;
        // border: 1px solid #ccecff;
      }
    }
  }
}
</style>
