/*
 * @Description:
 * @Author: zby
 * @Date: 2021-12-09 15:31:38
 * @LastEditors: zby
 * @Reference:
 */
import store from '@/store';
import router from '@/router';

const HAND_ERR_CODE_FN = {
  /* 401报错 */
  401: async () => {
    await store.dispatch('user/resetToken');
    router.push({ path: '/login' });
  },
};

const handErrCode = code => {
  Object.prototype.hasOwnProperty.call(HAND_ERR_CODE_FN, code) && HAND_ERR_CODE_FN[code]();
};

export default handErrCode;
