export const debounce = function (fun, delay) {
  let timer;
  return function () {
    clearTimeout(timer);
    let args = arguments;
    timer = setTimeout(() => {
      fun.apply(this, args);
    }, delay);
  };
};
export const throttle = function (fun, time) {
  let t1 = 0;
  return function () {
    let t2 = new Date();
    if (t2 - t1 > time) {
      fun.apply(this, arguments);
      t1 = t2;
    }
  };
};
