<script>
export default {
  name: 'MenuItem',
  functional: true,
  props: {
    icon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
  render(h, context) {
    const { icon, title } = context.props;
    const vnodes = [];

    if (icon) {
      if (icon.includes('el-icon')) {
        vnodes.push(<i class={[icon, 'sub-el-icon']} />);
      } else {
        // <GLIcon v-if="onlyOneChild.meta.icon" :icon="onlyOneChild.meta.icon" iconClass="icon_menu"></GLIcon>
        vnodes.push(<GLIcon icon={icon} iconClass="icon_menu" />);
      }
    }

    if (title) {
      vnodes.push(
        <span slot="title" class="menu_tit">
          {title}
        </span>
      );
    }
    return vnodes;
  },
};
</script>

<style scoped>
.sub-el-icon {
  color: currentColor;
  /* width: 1em; */
  /* height: 1em; */
}
.icon_menu {
  /* margin-right: 16px; */
  font-size: 18px !important;
}

.menu_tit {
  padding-left: 14px;
  font-size: 16px;
}
</style>
