import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'normalize.css';
import '@/styles/index.scss';

import i18n from './lang'; // internationalization
// import locale from 'element-ui/lib/locale';
// 设置element语言
// locale.i18n((key, value) => i18n.t(key, value));

import '@/iconfont/iconfont';
import '@/plugins/element';
import '@/components/index';
// import apiModules from '@/api/index';
import directives from '@/utils/directives';
// Vue.prototype.$API = apiModules;

// 引入 ag-grid
import 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise';
LicenseManager.setLicenseKey('MjAwMDAwMDAwMDAwMA==598447838c89eb4366146127615e40a4');

Vue.config.productionTip = false;
Vue.config.devtools = true;
Vue.use(directives);
let vm = new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this;
  },
});
vm.$mount('#app');

export default vm;
