const getters = {
  sidebar: state => state.app.sidebar,
  device: state => state.app.device,
  language: state => state.app.language,
  getToolData: state => state.app.toolBarData,
  getCacheView: state => state.app.cacheView,
  userConfig: state => state.app.userConfig,

  workId: state => state.user.workId,
  userName: state => state.user.name,
  avatar: state => state.user.avatar,
  elTreeMenu: state => state.user.elTreeMenu,
  roleNames: state => state.user.roleNames,
  directorName: state => state.user.directorName,
  pmName: state => state.user.pmName,
  boName: state => state.user.boName,
};
export default getters;
