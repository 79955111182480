<!--
 * @description 封装日期选择器
 * @param { clearDate } name description 因为双向绑定的值无法和传递过来的值同步，故借此值来在提交/取消时清空日期
  * @param { buildAttrs } name description 日期的基本配置
 * @return { Boolean } description...
 -->
<template>
  <div id="date-picker">
    <el-date-picker
      v-model="date"
      @change="onChange"
      v-bind="buildAttrs"
      :clearable="clearable"
      :align="align"
      :size="size"
      :picker-options="pickerOptions"
    ></el-date-picker>
  </div>
</template>

<script>
export default {
  name: 'DatePick',
  props: {
    // 单页面多日期选择器时可使用该参数进行复用处理
    dateCompName: {
      type: String,
    },
    size: {
      type: String,
      default: 'mini',
    },
    // 如果你需要限制开始时间的使用范围，那就传递以下参数，以YYYY-MM-DD的字符串形式传递即可
    startDate: {
      type: String,
      default: '',
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    align: {
      type: String,
      default: 'right',
    },
    dateValue: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  watch: {
    dateValue: function (newVal) {
      this.date = [...newVal];
    },
  },
  data() {
    return {
      date: [],
      // 禁选时间和可选时间段，必须要以对象内引用函数的形式调用，直接写在里面无法同步读取startDate
      pickerOptions: {
        disabledDate: this.disabledDate,
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
    };
  },
  methods: {
    onChange(list) {
      if (list && list.length > 0) {
        this.$emit('onChange', list, this.dateCompName);
        // 清空时调用下面的步骤
      } else {
        this.$emit('onChange', null, this.dateCompName);
      }
    },
    disabledDate(time) {
      return time.getTime() < new Date(this.startDate).getTime();
    },
  },
  computed: {
    // 默认配置项
    buildAttrs() {
      return {
        ...{
          type: 'daterange',
          align: 'right',
          'unlink-panels': true,
          'range-separator': '至',
          'start-placeholder': '开始月份',
          'end-placeholder': '结束月份',
          'value-format': 'yyyy-MM-dd',
        },
      };
    },
  },
};
</script>

<style></style>
