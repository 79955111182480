export default {
  route: {
    WorkRecord: '首页',
    wrongRoute: '路径错误',
    specialWorkingHours: '特殊工时填报',
    AuditManagement: '工时审核',
    PersonalWorking: '个人工时统计',
    ProjectManagement: '项目管理',
    ScheduleManagement: '项目进度',
    OperationManagement: '运营管理',
    ContractManagement: '合同管理',
    PublicAffairs: '公共事项',
    PublicManagement: '公共事项管理',
    ProjectData: '项目数据汇总',
    ResourceManagement: '资源管理',
    ProjectStatement: '项目管理',
    ProjectList: '项目列表',
    SponsorManagement: '申办方维护',
    ServiceManagement: '服务项维护',
    ClientManagement: '客户维护',
    PlanManagement: '方案维护',
    SystemConfig: '系统设置',
    DepartmentManagement: '部门管理',
    RoleManagement: '角色管理',
    UserManagement: '用户管理',
    ProjectFile: '项目归档',
    ContractEditor: '项目编辑',
    management: '系统管理',
    assistant: '助理管理',
    bo: 'BO 管理',
    BIManagement: 'BI 数据报表',
  },
  commonVariables: {
    sequenceNumber: '序号',
    viewOperationManual: '点击查看操作手册',
    HelpDocumentation: '帮助文档',
    SubmitWorkingHours: '填写工时',
    TaskItemProcess: '任务项进度',
    ViewPDF: '查看PDF文档',
    complete: '确认',
    details: '详情',
    cancel: '取消',
    reset: '重置',
    filtrate: '筛选',
    delete: '删除',
    Download: '点击下载',
    operate: '操作',
    edit: '编辑',
    save: '保存',
    add: '添加',
    notice: '提示',
    noPermission: '无操作权限',
    enterTips: '请输入',
    submittedSuccessfully: '提交成功',
    savedSuccessfully: '保存成功',
    confirmDelete: '确定放弃',
    saveWarnTips: '有未保存的数据, 确认放弃保存吗?',
    unfold: '展开',
    fold: '折叠',
  },
  workRecord: {
    fillProgress: '填写进度',
    projectName: '项目名称',
    nameCont: '任务项',
    nameContTips: '请输入任务项',
    withdrawalSucceeded: '撤回成功',
    seringParentName: '服务项父项',
    // 2023/1/6
    withdrawal: '撤回',
    addSupplement: '补充提交',
    withdrawalDes: '请确认是否撤回当前周该项目工时?',
    withdrawalTips: '注：修改保存后需在“工时审核进度”点击重新提交',
    addSupplementDes: '请确认是否补充提交当前周该项目工时?',
    addSupplementTips: '注：填写后需先点保存再提交，提交后不可修改',
    // 2023/1/4
    createNewServiceItems: '创建新的服务项',
    createServiceItems_Tips: '请输入要为此项目创建的服务项名称',
    //project choose
    project: '项目',
    findProject: '查找项目',
    publicAffairs: '公共事项',
    findPublicAffairs: '查找公共事项',
    //content
    projectHoursTable: '工时填报',
    save: '保存',
    currentWeek: '当前周',
    lastWeek: '上周',
    nextWeek: '下周',
    todayWorkingHours: '今日个人总工时',
    weeklyCurrentDayHours: '总工时',
    weeklyWorkingHours: '本周个人总工时',
    hours: '小时',
    //table
    finishNum: '个人累计已完成',
    size: '任务项数量',
    serviceItem: '服务项',
    serviceChildItem: '服务项子项',
    projectWorkItem: '项目工作项',
    taskItem: '任务项',
    contractNo: '合同序号',
    today: '今天',
    monday: '周一',
    tuesday: '周二',
    wednesday: '周三',
    thursday: '周四',
    friday: '周五',
    saturday: '周六',
    sunday: '周日',
    myWeekStatistics: '个人周统计',
    contractWeekStatistics: '项目周统计',
    tableInput: '输入工时',

    //填写进度
    confirmToClose: '确定要关闭吗？关闭后未保存的数据将丢失!',
    modifySuccess: '修改成功',
    note: '注意',
  },
  specialWorkingHours: {
    date: '时间',
    workload: '工作量',
    workContent: '工作内容(服务内容)',
    operation: '操作',
  },
  personalWorking: {
    progress: '工时审核进度',
    summary: '个人工时汇总',
    projectHours: '项目工时',
    affairsHours: '公共事项工时',
    confirmModification: '请确认已修改该条记录的工时，重新提交后不可再修改',
    confirmWorkHoursModification: '请确认本周工时填写是否正确无漏， 本次提交为整周工时情况， 提交后不可撤回、不可修改',
    //content
    personalWorkingHours: '个人工时统计',
    lastWeek: '上周',
    nextWeek: '下周',
    //table
    project: '项目',
    serviceItem: '服务',
    monday: '周一',
    tuesday: '周二',
    wednesday: '周三',
    thursday: '周四',
    friday: '周五',
    saturday: '周六',
    sunday: '周日',
    myWeekStatistics: '个人周统计',
    submitAuditRecord: '提交本周工时审核',
    // 审核进度
    projectName: '项目名称',
    approvals: '审批进度',
    submissionTime: '提交时间',
    reviewer: '审核人',
    reasonDismiss: '驳回原因',
    processingTime: '处理时间',
    operation: '操作',

    submit: '提交',
    cancel: '取消',
    notice: '提示',
    SubtotalDuration: '工时小计',
    TotalDuration: '工时合计',
    resubmit: '重新提交',
    Rejected: '已驳回',
    dismissReason: '提交人自行撤回',
  },
  contractManagement: {
    projectName: '项目名称',
    projectCode: '项目编号',
    contractCode: '合同编号',
    customerId: '客户id',
    contractType: '合同类型',
    name: '合同名称',
    projectShortName: '项目简称',
    shortName: '合同简称',
    year: '签订年份',
    orderDate: '签订日期',
    status: '项目状态',
    preContractUrl: '预先合同',
    finalContractUrl: '最终合同',
    customerCode: '客户编号',
    bdNames: 'BD',
    period: '期数',
    editContract: '编辑合同',
    newContract: '新建合同',
    update: '更新合同',
    uploadPreContract: '上传预先合同',
    uploadSigningContract: '上传最终合同',
    currency: '合同币别',
    amount: '合同金额',
    customerName: '客户名称',
    deleteWarning: '此操作将删除该合同记录，包括合同附件，是否继续？',
    createCustomer: '添加客户',
    editCustomer: '编辑客户',
    addDepartment: '添加部门',
    closeDrawerTips: '确定要关闭吗？关闭后将不会保存填写数据',
    projectSituation: '项目情况',
    else: '其它',
    businessContact: '业务联系人',
    procurementContact: '采购联系人',
    backToList: '返回列表',
    sales: 'S销售服务合同',
    purchase: 'P采购合同',

    doingProjectCount: '进行中',
    finishProjectCount: '已结束',
    sureDeleteFileTips: '确定移除',
  },

  projectManagement: {
    task: {
      name: '任务项',
      size: '任务项数量',
      assigns: '负责成员',
      contractCode: '合同序号',
      taskName: '项目工作项',
      seringParentName: '服务项父项',
      seringName: '服务项',
      editTaskItem: '编辑任务项',
      checkTaskItem: '查看任务项',
    },
    workItemconfirmValue: '工作项确认值',
    OperationalServiceItem: '合同服务项',

    //project choose
    deleteItem: '删除项目',
    replacementItem: '重启项目',
    endItem: '结束项目',
    project: '项目',
    findProject: '查找项目',
    clickCreateNewProject: '点击新建项目',
    //content
    projectHoursTable: '编辑项目',
    projectSchedule: '项目进度',
    createNewProject: '创建新项目',
    cancel: '取消',
    save: '保存',
    editServiceItem: '编辑服务项',
    editWorkItem: '编辑工作项',
    leadInServiceItem: '导出服务项',
    createNewServiceItem: '创建新的服务项',
    matchList: '匹配',
    addWorkItem: '新增项目工作项',
    createServiceItems_Tips: '请输入要为此项目创建的服务项名称',
    No: '序号',
    serviceItem: '合同服务项',
    name: '服务项',
    projectCode: '合同编号',
    projectSeq: '合同序号',
    servingManualAmountSize: '服务项累计完成',
    finishPercent: '服务项累计进度',
    taskManualAmountSize: '工作项累计完成',
    contractSize: '合同数量',
    contractUnit: '合同单位',
    unitPrice: '单价',
    amount: '金额',
    discount: '折扣',
    currency: '货币',
    module: '模块',
    remark: '备注',
    initialSize: '初次完成数量',
    initialAmount: '初次完成金额',
    referenceTotalSize: '任务项总量',
    referenceSize: '任务项完成量',
    workTotalSize: '工作项总量',
    workItem: '工作项',
    projectWork: '项目工作项',
    number: '数量',
    standardWork: '标准工作项',
    standardUnit: '标准单位',
    serviceDetail: '服务项明细',
    operation: '操作',
    add: '添加',

    //项目管理
    confirmToLeave: '你有已编辑的工作项尚未保存，确定要离开吗？离开后未保存的内容将丢失',
    viewUnsignTask: '查看未分配的任务项',
    chooseTime: '选择时间',
    confirmSubmit: '确认提交',
    sow: 'sow',
    fillinProgress: '填写进度(数量)',
    projectType: '项目类型',
    executiveDepartment: '执行部门',
    projectBelong: '项目归属',
    projectNo: '项目编号',
    projectName: '项目名称',
    projectBO: '客户经理BO',
    // projectPM: '首席项目管理(PM)',
    //projectLSP: '项目负责人LSP',
    projectLEAD: '项目LEAD',
    // projectAuditDirector: '项目审核总监',
    projectAD: '直属副总监AD',
    projectDirector: '直属总监D',
    Member: '成员',
    TimeFrame: '时间范围',
    confirmToMatch: '你有已编辑的的工作项尚未保存，确定要匹配吗？匹配后未保存的内容将丢失',
    note: '注意',
    delete: '删除',
    createProWorkItem: '新建项目工作项',
    serveName: '服务项名称',
    contractNo: '合同序号',
    servingFather: '服务项（父级）',
    serveItem: '服务项',
    taskItem: '任务项',
    proWorkItem: '项目工作项',
    chargeMember: '负责成员',
    taskItemName: '任务项名称',
    num: '数量',
    member: '成员',
    addMember: '添加成员',
  },
  operationManagement: {
    project: '项目',
    findProject: '查找项目',
    //content
    projectHoursTable: '编辑项目',
    createNewProject: '创建新项目',
    batchModification: '批量修改',
    cancel: '取消',
    save: '保存',
    editServiceItem: '编辑服务项',
    createNewServiceItem: '创建新的服务项',
    createServiceItems_Tips: '请输入要为此项目创建的服务项名称',
    No: '序号',
    serviceItem: '服务项',

    name: '服务项',
    projectCode: '合同编号',
    projectSeq: '合同序号',
    operation: '操作',
  },
  projectData: {
    projectDataSummary: '工时数据汇总',
    ProjectStatement: '项目报表',
    sponsorStatement: '申办方报表',
    projectServiceCountStatement: '服务项目统计报表',
    PMServiceworkHourMonthly: 'PM任务项目工时报表',
    staffWorkHourStatement: '员工任务工时报表',
    filter: {
      reset: '重置',
      search: '筛选',
    },
    table: {
      specialIdentification: '特殊标识',
      sponsor: '申办方',
      sponsorId: '申办方编号',
      projectName: '项目名称',
      proejectInitiationDate: '项目开始时间',
      proejectFinishDate: '项目结束时间',
      protocolName: '方案',
      clientCode: '客户编号',
      client: '客户',
      department: '部门',
      start: '开始时间',
      end: '结束时间',
      contractStartYear: '合同启用年份',
      BO: 'BO',
      PM: 'PM',
      realBO: 'Senior Manager',
      realPM: 'Manager',
      director: '总监',
      member: '成员',
      serviceItem: '服务项',
      staffName: '员工姓名',
      stageWorkHour: '阶段工时',
      countWorkHour: '累计工时',
      monthlyWorkHour: '月度工时',
      annualWorkHour: '年度累计工时',
    },
    export: {
      staffWorkHourStatementExport: '员工任务工时报表导出',
    },
  },
  AuditManagement: {
    auditing: '待审核（工时期间）',
    audited: '已审核（工时期间）',
    pendingApprove: '待审批',
    haveApproved: '已审批',
    rejected: '驳回',
    access: '通过',
    allAccess: '全部通过',
    searchDate: '查找日期',
    member: '成员',
    totalDuration: '周合计',
    detail: '详情',
    status: '状态',
    submitDate: '提交时间',
    // 12/28
    Passed: '已通过',
    Completed: '已完成',
    Autocommit: '自动提交',
    Unapproved: '待审核',
    Resubmit: '重新提交',
    Rejected: '已驳回',
    uncommitted: '未提交',
    AutomaticApproval: '自动通过',
    ViewDetails: '点击查看',
    WorkingHoursRange: '工时大于44小时/周、8小时/天',
    overLeaveHours: '单天请假大于4小时',
    limitLeaveHours: '单天请假小于等于4小时',
  },
  ResourceManagement: {
    sponsor: {
      name: '申办方简称',
      fullname: '申办方全称',
      creator: '创建人',
      createdAt: '创建时间',
      remark: '备注',
    },
    customer: {
      sequenceNumber: '序号',
      code: '客户编号',
      name: '客户名称',
      creator: '创建人',
      createdAt: '创建时间',
      remark: '备注',
      type: '客户类型',
      /*       inProgress: '进行中',
      finished: '已结束', */
      bdName: '跟进BD',
      addRecord: '添加记录',
      editRecord: '编辑记录',
      uploadRecord: '上传记录',
      trackingAt: '沟通时间',
      bdNames: 'BD',
      doingProjectCount: '进行中',
      finishProjectCount: '已结束',
      finished: '已结束',
      projects: '项',
      sponsorName: '申办方',
      contactName: '联系人',
      contact: '联系人',
      contactPosition: '职位',
      contactPhone: '联系方式',
      projectCountPerYear: '项目数量/年',
      projectBeginAt: '项目开展时间',
      projectByStages: '项目分期',
      projectBudget: '项目预算',
      policymaker: '决策人',
      customerDemand: '客户需求',
      policyFlow: '决策流程',
      actionPlan: '行动计划',
      details: '客户详情',
      follow: '跟进',
      basicInformation: '基础信息',
      area: '所属区域',
      address: '通讯地址',
      Contact: '联系人',
      fillInPersonTips: '（*至少填写一个联系人）',
      contactType: '联系人类型',
      contactPersonName: '联系人姓名',
      associatedContracts: '关联合同',
      enterContractNo: '请输入项目名称',
      addContracts: '添加合同',
      deleteCustomerTips: '此操作将删除该客户的所有信息，包括详情及跟进记录, 是否继续?',
      deleteContactTips: '此操作将同时删除该联系人信息，以及所关联的合同编号信息, 是否继续?',
      followRecord: '跟进记录',
      reset: '重置',
      screen: '筛选',
      KeywordTips: '关键字（需求/流程/计划）',
      keywords: '关键字',
      deleteFollowTips: '此操作将删除该跟进记录所有信息，是否继续？',
      editContact: '编辑联系人',
      addContact: '新建联系人',

      projectSituation: '项目情况',
      else: '其它',
      businessContact: '业务联系人',
      procurementContact: '采购联系人',
      accountant: '会计联系人',

      dm: 'DM联系人',
      stat: 'STAT联系人',
      pk: 'PK联系人',
      med: 'MED联系人',
      co: 'CO联系人',
      prog: 'PROG联系人',

      backToList: '返回列表',
      sales: 'S销售服务合同',
      purchase: 'P采购合同',
      followProgress: '跟进进度',
      searchTips: '请输入客户名称/编号',

      chooseFile: '请选择文件',
      searchFile: '查找文件',
      dowloadTemplate: '下载模板',
      download: '点击下载',
      import: '导入',
    },
    detail: {
      addContact: '添加联系人',
      name: '客户联系人',
      types: '联系人类型',
      position: '职位',
      email: 'email',
      invoiceEmail: '手机号码',
      businessPhone: '办公号码',
      ccEmail: '通讯地址',
      sureCloseTips: '确认关闭？',
      closeDetailTips: '确认关闭？您编辑的内容将会被撤销。',
    },

    plan: {
      code: '方案编号',
      name: '方案名称',
      sponsorName: '归属申办方',
      phaseName: '临床分期',
      indicationName: '适应症',
      therapeuticName: '治疗领域',
      destPersonSize: '入组目标人数',
      siteCountry: 'site country',
    },
    service: {
      name: '服务明细',
      description: '详细说明',
      category: '分类',
      chineseCategory: '分类',
      status: '状态',
    },
    exportData: '导出',
    createCustomer: '添加客户',
    editCustomer: '编辑客户',
    createSponsor: '添加申办方',
    createClientDes: '请输入要为此项目创建的客户名称',
    createSponsorDes: '请输入要为此项目创建的申办方名称',
    operation: '操作',
  },
  publicAffairs: {
    //affair choose
    affair: '事项',
    findAffair: '查找事项',
    //content
    publicItemHoursTable: '公共事项工时汇总表',
    save: '保存',
    lastWeek: '上周',
    nextWeek: '下周',
    todayWorkingHours: '今日个人总工时',
    weeklyWorkingHours: '本周个人总工时',
    //table
    task: '任务',
    monday: '周一',
    tuesday: '周二',
    wednesday: '周三',
    thursday: '周四',
    friday: '周五',
    saturday: '周六',
    sunday: '周日',
    myWeekStatistics: '个人周统计',
    contractWeekStatistics: '项目周统计',
    tableInput: '输入工时',
  },
  publicManagement: {
    //affair choose
    affair: '事项',
    findAffair: '查找事项',
    clickCreateNewAffair: '点击新建事项',
    //content
    createNewAffair: '创建新事项',
    editAffair: '编辑事项',
    cancel: '取消',
    save: '保存',
    editTaskItem: '编辑任务项',
    checkTaskItem: '查看任务项',
    createNewTaskItem: '创建新的任务项',
    leadingInTaskItem: '批量导入服务项',
    createTaskItems_Tips: '请输入要为此事项创建的任务项名称',
    No: '序号',
    taskItem: '任务',
    operation: '操作',
  },
  systemConfig: {
    addDepartment: '添加部门',
    save: '保存',
    findDepartment: '输入部门名称',
    roleName: '角色名称:',
    roleType: '角色类型：',
    roleStatus: '状态：',
    enterRoleName: '输入角色名称',
    addRole: '新增',
    createRole: '新增角色',
    editRole: '编辑角色',
    basicInfo: '基本信息',
    menuPermissions: '菜单权限',
    enterUserName: '输入用户名称',
    editUser: '编辑用户',
    baseInfo: '基本信息',
    roleInfo: '角色信息',
    superiorInfo: '上级信息',
    specialPermissionsConfig: '特殊权限配置',
    jobNo: '工号',
    name: '姓名',
    department: '部门',
    userStatus: '状态',
    director: 'Director',
    enterName: '输入BO/PM姓名',
    email: '邮箱',
    role: '角色',
    ok: '确定',
    cancel: '取消',
  },
};
