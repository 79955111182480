/*
 * @Description:
 * @Author: jinsheng.yang
 * @Date: 2022-05-13 11:16:05
 * @LastEditors: jinsheng.yang
 * @Reference:
 */
import { BaseService } from '@/api/request';

const api = {};

/**
 * @description: 获取部门列表
 * @param  {*page 页码}
 * @param  {*pagesize 页面大小}
 * @param  {*nameCont 检索关键字}
 */
api.groups = function (method = 'get', params = {}) {
  const { id } = params;
  const url = id ? `api/pmf/v1/groups/${id}` : 'api/pmf/v1/groups';
  return BaseService.restfulRequest(url, method, params);
};
/**
 * @description: 获取角色列表
 * @param  {*page 页码}
 * @param  {*pagesize 页面大小}
 * @param  {*nameCont 检索关键字}
 */
api.roles = function (method = 'get', params = {}) {
  const { id } = params;
  const url = id ? `api/pmf/v1/roles/${id}` : 'api/pmf/v1/roles';
  return BaseService.restfulRequest(url, method, params);
};
/**
 * @description: 获取用户列表
 * @param  {*page 页码}
 * @param  {*pagesize 页面大小}
 * @param  {*nameCont 检索关键字}
 */
api.users = function (method = 'get', params = {}) {
  const { id } = params;
  const url = id ? `api/pmf/v1/users/${id}` : 'api/pmf/v1/users';
  return BaseService.restfulRequest(url, method, params);
};
/**
 * @description: 获取部门下的管理列表
 * @param  {*ids 部门（一个或多个）的id}
 */
api.groupManagers = function (ids) {
  return BaseService.restfulRequest(`api/pmf/v1/groupManagers`, 'get', { ids });
};
/**
 * @description: 获取所有部门和用户列表
 * @param  {*id 部门id}
 */
api.groupUsers = function () {
  return BaseService.restfulRequest('api/pmf/v1/groupUsers', 'get');
};

/**
 * @description: 获取页面列表
 */
api.getMenus = function () {
  return BaseService.restfulRequest('api/pmf/v1/menus', 'get');
};
/**
 * @description: 获取页面列表
 */
api.getAuthInfo = function () {
  return BaseService.restfulRequest('api/pmf/v1/auth/info', 'get');
};
api.getAuthSetting = function () {
  return BaseService.restfulRequest('/api/pmf/v1/settings/menuVisions', 'get');
};
api.saveAuthSetting = function (params = {}) {
  return BaseService.putRequest('/api/pmf/v1/settings/menuVisions', { menuVisions: params });
};
api.logout = function () {
  return BaseService.delRequest('/api/pmf/v1/auth/logout');
};
export default api;
