import router from '@/router';
import * as types from './mutation-types';
const mutations = {
  [types.SETOOLDATA](state, data) {
    // 添加标签按钮，如果当前路由已经打开，则不再重复添加
    const inToolbar = state.toolBarData.find(item => item.detail === data.detail);
    !inToolbar &&
      state.toolBarData.push({
        ...data,
      });
  },
  SETPERSONALDATA(state, turn) {
    state.dialogVisible = turn;
  },
  [types.SETCACHEVIEW](state, data) {
    // 与setToolData类似
    if (state.cacheView.includes(data.componentName)) return;
    if (!data.alive) return;
    state.cacheView.push(data.componentName);
  },
  [types.CLEARTOOLITEM](state, detail) {
    const index = state.toolBarData.findIndex(item => item.detail === detail);
    const isActive = router.app.$route.path == state.toolBarData[index]['detail'];
    const len = state.toolBarData.length - 1;
    state.toolBarData.splice(index, 1);
    (index == len || isActive) && router.push({ path: state.toolBarData[state.toolBarData.length - 1]['detail'] });
  },
  [types.CLEARCACHEVIEW](state, viewName) {
    const index = state.cacheView.findIndex(item => item == viewName);
    state.cacheView.splice(index, 1);
  },
  TOGGLE_SIDEBAR: state => {
    state.sidebar.opened = !state.sidebar.opened;
    state.sidebar.withoutAnimation = false;
    if (state.sidebar.opened) {
      localStorage.setItem('sidebarStatus', 1);
    } else {
      localStorage.setItem('sidebarStatus', 0);
    }
  },
  CLOSE_SIDEBAR: (state, withoutAnimation) => {
    localStorage.setItem('sidebarStatus', 0);
    state.sidebar.opened = false;
    state.sidebar.withoutAnimation = withoutAnimation;
  },
  TOGGLE_DEVICE: (state, device) => {
    state.device = device;
  },
  SET_LANGUAGE: (state, language) => {
    state.language = language;
    localStorage.setItem('language', language);
  },
  SET_USERCONFIG: (state, config) => {
    state.userConfig = config;
  },
};

export default mutations;
