import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);
import store from '@/store';

import NProgress from 'nprogress'; // progress bar
import 'nprogress/nprogress.css'; // progress bar style
NProgress.configure({ showSpinner: false }); // NProgress Configuration

import { getToken } from '@/utils/auth'; // get token from cookie
import vm from '@/main';

const whiteList = ['/login', '/loginCallback']; // no redirect whitelist
// import { constantRoutes } from '@/router/definitionRoutes';
import staticRoutes from '@/router/static-routes.js';
import dynamicRoutes from '@/router/dynamic-routes.js';
import logging from '@/utils/logging';

import systemConfigApi from '@/api/modules/systemConfigApi.api.js';

const routes = [...staticRoutes];
const router = new VueRouter({
  mode: 'hash',
  routes,
  scrollBehavior: () => ({ y: 0 }),
});

async function getAuthorizationRoutes(resolve, reject) {
  // 读取权限列表
  const res = await systemConfigApi.getAuthInfo();
  if (res && res.status === 200) {
    const userInfo = res?.data?.data || {};
    await store.dispatch('user/getInfo', userInfo);

    const menus = userInfo?.menus;
    if (Array.isArray(menus)) {
      /* 动态路由过滤 */
      const authorizationRoutes = dynamicRoutes.filter(route => {
        if (route.children) {
          route.children = route.children.filter(r => {
            return menus.includes(r.key);
          });
          return route.children.length;
        }
        // 首页无需走鉴权步骤
        return menus.includes(route.key) && route.key !== 'WorkRecord';
      });
      userInfo.authorizationRoutes = authorizationRoutes;
      userInfo.asyncFinished = true;
      userInfo.permissionsKeys = {};

      if (Array.isArray(userInfo.permissions)) {
        userInfo.permissions.forEach(k => {
          userInfo.permissionsKeys[k] = true;
        });
      }
      router.addRoutes([...authorizationRoutes]);
      router.addRoute({ path: '*', redirect: '/404', hidden: true });
      store.commit('app/SET_USERCONFIG', userInfo);
      resolve();
    }
  } else {
    reject();
  }
}

const hash = window.location.hash;

router.beforeEach(async (to, from, next) => {
  // start progress bar
  NProgress.start();
  /* 路由发生变化修改页面title */
  setTimeout(() => {
    to.meta.title ? (document.title = vm.$i18n.t('route.' + to.meta.title)) : (document.title = '杰纳医药');
  }, 100);

  // determine whether the user has logged in
  const hasToken = getToken();

  if (hasToken) {
    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      next({ path: '/' });
      NProgress.done();
    } else {
      // 获取经过鉴权后的路由表
      const asyncRoutes = new Promise(getAuthorizationRoutes);
      // 存入token
      await store.dispatch('user/login');
      // /404是为了匹配所有动态路由用的，在没有指定路由的情况下默认跳转至404
      // 这里就是为了处理跳转到404页面的情况
      if (to.path === '/404' && !store.state.app.userConfig.asyncFinished) {
        logging(true, '加载页面');
        asyncRoutes.then(() => {
          logging(false);
          // 跳到指定的路由
          next(hash.slice(1));
        });
      }
      // 否则执行默认跳转
      else {
        next();
      }
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      // 非登录指引类别的页面，直接按默认跳转next()处理，其会指引至登录指引类别的页面
      next();
    } else {
      // 带上url的全url信息，然后携带到login的逻辑中进行处理
      // other pages that do not have permission to access are redirected to the login page.
      next({ path: '/login', query: { from: to.fullPath } });
    }
  }
});

router.afterEach(() => {
  NProgress.done(); // finish progress bar
});

export default router;

/* 登录流程 */
//1. 判断是否存在token
//2. 存在就直接进行跳转或进行权限页面校验
//3. 不存在token就跳到Login登录页，在login的逻辑中判断url上是否存在session字段
//4. 存在session就以此发请求获取真正的token并存储并刷新页面 ===> 回到第一步
//5. 不存在session就跳转到login路由以用来跳转到扫码登录页面=>扫码登录=>后端重定向回来并带上session字段在url上 ===> 回到第一步
