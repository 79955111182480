<template>
  <div :class="{ 'has-logo': showLogo }">
    <logo v-if="showLogo" :collapse="isCollapse" />
    <div class="outer-container">
      <el-menu
        @select="showOffSetting"
        @open="showOffSetting"
        :default-active="activeMenu"
        :collapse="isCollapse"
        :background-color="variables.menuBg"
        :text-color="variables.menuText"
        :unique-opened="true"
        :active-text-color="variables.menuActiveText"
        :collapse-transition="false"
        mode="vertical"
      >
        <sidebar-item v-for="route in constantRoutes" :activePath="activePath" :key="route.path" :item="route" :base-path="route.path" />
      </el-menu>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Logo from './Logo';
import SidebarItem from './SidebarItem';
import variables from '@/styles/variables.scss';
import staticRoutes from '@/router/static-routes';

export default {
  components: { SidebarItem, Logo },
  computed: {
    ...mapGetters(['sidebar', 'userConfig']),
    constantRoutes() {
      const dynamicRoutes = this.userConfig.authorizationRoutes || [];
      return staticRoutes.concat(dynamicRoutes);
    },
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      this.defaultActive();
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }

      return path;
    },
    showLogo() {
      // return this.$store.state.settings.sidebarLogo;
      return true;
    },
    variables() {
      return variables;
    },
    isCollapse() {
      return !this.sidebar.opened;
    },
  },

  data() {
    return {
      activePath: '',
    };
  },
  methods: {
    defaultActive() {
      if (this.$route.meta?.parentPath) {
        this.activePath = this.$route.meta.parentPath;
      } else {
        this.activePath = this.$route.path;
      }
    },
    showOffSetting(val) {
      this.activePath = val;
      this.$store.commit('app/SETPERSONALDATA', false);
    },
  },
};
</script>
<style lang="scss">
.sidebar-logo-link {
  background-color: #262f3e;
  border-bottom: 1px solid #354254;
}
.outer-container {
  border: 0 !important;
  border-top: 6px solid #1e222d !important;
  ul {
    background-color: #1e222d !important;
    div {
      .el-menu-item,
      .el-submenu__title {
        background-color: #1e222d !important;
        height: 48px;
        line-height: 48px;
        span {
          height: 100%;
          font-size: 14px;
          margin-left: 14px;
          padding-left: 0px !important;
        }
        svg {
          height: 15px;
          width: 15px;
        }
        .el-icon-arrow-down {
          margin-right: 8px;
        }
      }
      .nest-menu {
        .el-menu-item {
          background-color: #101117 !important;
          height: 40px;
          line-height: 40px;
          span {
            height: 100%;
            font-size: 13px;
            margin-left: 14px;
            padding-left: 0px !important;
          }
        }
      }
    }
  }
}
</style>
