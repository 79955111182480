<!--
阿里巴巴图标库使用说明：
icon--对应图片库font class名
iconClass--自定义类名
<GLIcon icon="icon-404" fontSize="16px" iconClass="hk-header-list-icon"></GLIcon>
-->
<template>
  <!-- <svg
    :class="['gl-icon', iconClass, iconClosed, { unactiveBorder: extraStyle }, { activeBorder: clickCheck && extraStyle }]"
    aria-hidden="true"
    :style="{ fontSize: fontSize }"
    preserveAspectRatio="none meet"
    @mousedown="switchColor(1)"
    @mouseup="switchColor(0)"
  >
    <use :xlink:href="'#' + (clickCheck ? downIcon : icon)"></use>
  </svg> -->
  <svg
    :class="['gl-icon', iconClass, iconClosed, { unactiveBorder: extraStyle }, { activeBorder: extraStyle }]"
    aria-hidden="true"
    :style="{ fontSize: fontSize }"
    preserveAspectRatio="none meet"
  >
    <use :xlink:href="'#' + icon"></use>
  </svg>
</template>

<script>
export default {
  name: 'GLIcon',
  props: {
    viewBox: {
      type: String,
      default: '',
    },
    extraStyle: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      require: true,
    },
    iconClass: {
      type: String,
      default: '',
    },
    iconClosed: {
      type: String,
      default: '',
    },
    downIcon: {
      type: String,
      default: '',
    },
    fontSize: {
      type: String,
      default: '1em',
    },
  },
  // data() {
  //   return {
  //     clickCheck: false,
  //   };
  // },
  // methods: {
  //   switchColor(bool) {
  //     if (bool) {
  //       this.clickCheck = true;
  //     } else {
  //       this.clickCheck = false;
  //     }
  //   },
  // },
};
</script>

<style scoped>
.gl-icon {
  width: 1em;
  height: 1em;
  /* vertical-align: middle; */
  fill: currentColor;
  overflow: hidden;
}
.unactiveBorder {
  border-radius: 4px;
  border: 1px solid #e8e8e8;
}
.activeBorder {
  border: 1px solid rgba(24, 144, 255, 0.2);
}
</style>
